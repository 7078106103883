<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox">
            <mat-card-title class="title"> Overtime Slab Configure</mat-card-title>
        </mat-card-header>
        <!-- 
        <div fxLayout="row" style="margin-top: 25px;">
            <span fxFlex="50">
                <mat-radio-group (change)="radioChange($event)">
                    &nbsp;  
                    <mat-radio-button value="0" [checked]="IsOvertime"><b>Yes</b></mat-radio-button>&nbsp;
                    <mat-radio-button value="1" [checked]="!IsOvertime"><b>No</b></mat-radio-button>
                </mat-radio-group>
            </span>
        </div> -->
        <div fxLayout="column">
            <form [formGroup]="OTConfigForm" class="tableP" (ngSubmit)="ConfigureOT()">
                <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center">
                    <mat-form-field fxFlex="30" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>State</mat-label>
                        <mat-select formControlName="stateId" [tabIndex]="1">
                            <input matInput [formControl]="searchControl" type="text" placeholder="  Search"
                                class="search-align">
                            <ng-container>
                                <div *ngFor="let b of filteredStates">
                                    <mat-option [value]="b.stateid">{{ b.statename }}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="OTConfigForm.controls.stateId.errors?.required">
                            Please select an option.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="30" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Slab Percentage</mat-label>
                        <input matInput formControlName="slabPercentage" placeholder="%" required maxlength="5"
                            (change)="onchangeSlabQuotient()" appDecimalLimit>
                        <!--  (keypress)="numbersOnly($event)" -->
                        <mat-error class="con-error" *ngIf="OTConfigForm.controls.slabPercentage.errors?.required">
                            This field is required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayoutAlign="left" style="padding-left: 25%;">
                    <button class="btn btn-primary mr-2 mb-2" [disabled]="!OTConfigForm.valid || btnDisable"
                        [ngClass]="{'disable':btnDisable}" type="submit" (change)="onchangeSlabQuotient()">
                        <span>Save</span>
                    </button>
                    <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>

            </form>

            <div class="tableP">
                <div class="example-container mat-elevation-z1">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef>S. No.</th>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                        </ng-container>
                        <ng-container matColumnDef="stateName">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> State Name</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                <span>{{row.statename}} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="slabquotient">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Slab Percentage(%) </th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                <span>{{row.slabquotient}} </span>
                            </td>
                        </ng-container>

                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
            </div>
        </div>

    </mat-card>

</div>