<div fxLayout="column" class="first-col">
    <mat-card fxLayout="column">
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Variable DA Configuration </mat-card-title>
            <div style="margin-right: 2%" *ngIf="isdata">
                <button mat-icon-button style="padding-right: 40px" (click)="Add()" matTooltip="Add New">
                    <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <form [formGroup]="variableDaForm" autocomplete="off" *ngIf="!isdata">
            <div fxLayout="column" class="tableP">
                <!-- 1st row -->
                <div fxLayout.lt-md="column">
                    <span fxFlex="18" fxFlex.lt-lg="40" class="col-pad">Notification Name :</span>

                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Notification Name</mat-label>
                        <input formControlName="notification_name" required matInput maxlength="64"
                            placeholder="Please enter data" />
                        <mat-error class="con-error" *ngIf="variableDaForm.controls.notification_name.errors?.required">
                            This field is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout.lt-md="column">
                    <span fxFlex="18" fxFlex.lt-lg="40" class="col-pad">Published Name :</span>

                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Published Name</mat-label>
                        <input formControlName="published_name" required matInput maxlength="64"
                            placeholder="Please enter data" />
                        <mat-error class="con-error" *ngIf="variableDaForm.controls.published_name.errors?.required ">
                            This field is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout.lt-md="column">
                    <span fxFlex="18" fxFlex.lt-lg="40" class="col-pad">Wages Linked CPI Points :</span>
                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>CPI Points</mat-label>
                        <input formControlName="wages_linked_cpi_points" matInput appSingleDotAllow maxlength="6"
                            required placeholder="Please enter data" />
                        <mat-error class="con-error"
                            *ngIf="variableDaForm.controls.wages_linked_cpi_points.errors?.required ">
                            This field is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout.lt-md="column">
                    <span fxFlex="18" fxFlex.lt-lg="40" class="col-pad">CPI points at notified date :</span>
                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>CPI points at notified date</mat-label>
                        <input formControlName="cpi_points_at_notified_date" appSingleDotAllow matInput maxlength="6"
                            required placeholder="Please enter data" />
                        <mat-error class="con-error"
                            *ngIf="variableDaForm.controls.cpi_points_at_notified_date.errors?.required ">
                            This field is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout.lt-md="column">
                    <span fxFlex="18" fxFlex.lt-lg="40" class="col-pad">Notification Date :</span>
                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Date</mat-label>
                        <input matInput readonly required [matDatepicker]="notification_date" [min]="minDate"
                            [max]="maxDate" placeholder="DD-MM-YYYY" formControlName="notification_date"
                            (click)="notification_date.open()">
                        <mat-datepicker-toggle class="datepicker" matSuffix
                            [for]="notification_date"></mat-datepicker-toggle>
                        <mat-datepicker #notification_date></mat-datepicker>

                        <mat-error class="con-error"
                            *ngIf="variableDaForm.controls.notification_date.errors?.required ">
                            Please select an option.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout.lt-md="column">
                    <span fxFlex="18" fxFlex.lt-lg="40" class="col-pad"> VDA rate per point :</span>
                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label> VDA rate per point</mat-label>
                        <input formControlName="vda_rate_per_point" appDecimalLimit matInput maxlength="5" required
                            placeholder="%" />
                        <mat-error class="con-error"
                            *ngIf="variableDaForm.controls.vda_rate_per_point.errors?.required ">
                            This field is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout.lt-md="column">
                    <span fxFlex="18" fxFlex.lt-lg="40" class="col-pad"> Effective date :</span>
                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Effective Date</mat-label>
                        <mat-select formControlName="effective_start_date" required>
                            <ng-container>
                                <div *ngFor="let b of effectiveDateList">
                                    <mat-option [value]="b.effectiveDateId">{{b.effectiveDateValue}}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="con-error"
                            *ngIf="variableDaForm.controls.effective_start_date.errors?.required ">
                            Please select an option.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" style="align-items: center;">
                    <span *ngIf="pdfName" class="no-break" style="margin-left: 7px;">Upload File:</span>&nbsp;
                    <button class="toolbar-button" ngClass.lt-md="mob_file" mat-button (click)="fileView();"
                        class="link" style="font-size: 16px;text-align: start;" *ngIf="pdfName">
                        {{ pdfName }}
                    </button>
                    &nbsp;
                    <input *ngIf="!pdfName" style="margin-left: 0px" type="file" formControlName="document"
                        (change)="onSelectFile($event)" single />
                    &nbsp;
                    <mat-icon class="toolbar-button" mat-button title="Edit" *ngIf="pdfName"
                        (click)="editdoc()">edit</mat-icon>
                    &nbsp;
                    <span *ngIf="isFile" style="margin-top: 5px;">
                        <mat-icon title="Remove" (click)="deleteIcon()">delete</mat-icon>
                    </span>
                    &nbsp;
                </div>

                <div fxLayoutAlign="center">
                    <button class="btn btn-primary mr-2 mb-2" [disabled]="btnDisable" (click)="submitData()"
                        [ngClass]="{'disable':btnDisable}" type="submit">
                        <span *ngIf="!isEdit">Save</span>
                        <span *ngIf="isEdit">Update</span>
                    </button>

                    <button class="btn btn-danger mr-2 mb-2" (click)="resetform()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </form>

        <!-- <hr class="line_break"> -->

        <div fxLayout="column" *ngIf="isdata" class="table-pad">
            <div fxLayout="row" fxLayoutAlign="end" style="height: 50px;">
                <mat-form-field fxFlex="28" fxFlex.lt-lg="50" fxFlex.xs="100%" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="" #input />
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>
            <br />
            <div fxLayout="column">
                <div class="mat-elevation-z1 example-container">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef>S. No.</th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                {{ i + 1 + paginator.pageIndex * paginator.pageSize }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="notifyDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">
                                Notification Date
                            </th>
                            <td mat-cell *matCellDef="let row">{{ row.notificationdate }}</td>
                        </ng-container>

                        <ng-container matColumnDef="notification">
                            <th mat-header-cell *matHeaderCellDef>Notification</th>
                            <td mat-cell *matCellDef="let row" style="text-overflow:ellipsis;">{{ row.notification }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="publishedname">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Published Name</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">{{ row.publishedname }}</td>
                        </ng-container>

                        <ng-container matColumnDef="cpipoints">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">CPI Points</th>
                            <td mat-cell *matCellDef="let row">{{ row.cpipoints }}</td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-icon-button (keyup.enter)="edit(row)">
                                    <mat-icon (click)="edit(row)" title="edit">visibility</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="7" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
            </div>
        </div>
    </mat-card>
</div>