<div fxLayout="column" class="first-col" *ngIf="isreg">
  <mat-card>
    <mat-card-header>
      <mat-card-title> Attendance Regularization </mat-card-title>
    </mat-card-header>
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end"
        style="height: 70px;padding-top: 1%;">
        <mat-form-field fxFlex="28" fxFlex.lt-lg="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" [tabIndex]="1" placeholder="Search" #input>
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="tableP">
        <div class="mat-elevation-z1 example-container">
          <form [formGroup]="attendanceRequestForm" autocomplete="off">
            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-table">
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef> S. No.</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  <!-- {{ (i+1) }} -->

                  <!-- {{i+1}} -->
                  {{element.rid}}
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="applieddate">
                        <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Applied On </th>
                        <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.applieddate  | date:'dd-MM-yyyy'}}</td>
                    </ng-container> -->

              <ng-container matColumnDef="empoyeeid">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Date </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.attendancedate |
                  date:'dd-MM-yyyy'}}
                </td>
              </ng-container>
              <ng-container matColumnDef="empname">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Employee Name </th>
                <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
              </ng-container>
              <ng-container matColumnDef="shift">
                <th mat-header-cell *matHeaderCellDef> Shift </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.shiftname}}</td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="intime">
                <th mat-header-cell *matHeaderCellDef> In Time</th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span *ngIf="enable != row.attendanceid"> {{row.logintime}}</span>
                  <span *ngIf="enable === row.attendanceid">
                    <mat-form-field appearance="outline">
                      <input formControlName="intime" matInput mask="00:00:00" placeholder="{{ row.logintime}}"
                        maxlength="100" />

                    </mat-form-field>
                  </span>
                </td>
              </ng-container>

              <!-- Color Column -->
              <ng-container matColumnDef="outtime">
                <th mat-header-cell *matHeaderCellDef> Out Time</th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span *ngIf="enable != row.attendanceid">{{row.logouttime}} </span>
                  <span *ngIf="enable === row.attendanceid">
                    <mat-form-field appearance="outline">
                      <input formControlName="outtime" matInput mask="00:00:00" placeholder="{{ row.shift_end_time }}"
                        maxlength="100" />
                      <!-- <mat-error class="con-error"
                                    *ngIf="attendanceRequestForm.controls.outtime.errors?.required">
                                    {{ errorDesName }}
                                  </mat-error> -->
                    </mat-form-field>
                  </span>
                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="workedHours">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Worked Hours</th>
                <td mat-cell *matCellDef="let row"> {{row.workedHours}}</td>
              </ng-container> -->

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <button mat-icon-button [tabIndex]="2" (keyup.enter)="edit(row)"
                    *ngIf="row.isedit && enable != row.attendanceid && !row.logouttime">
                    <mat-icon class="mr-1" matTooltip="Edit" (click)="edit(row)">edit</mat-icon>
                  </button>

                  <button mat-icon-button [tabIndex]="2" (keyup.enter)="add(row)" *ngIf="row.logouttime">
                    <mat-icon class="mr-1" matTooltip="Add" (click)="add(row)">
                      {{ source && isAddEnable === row.attendanceid ? 'expand_less' : 'add_circle' }}</mat-icon>
                  </button>

                  <!-- <button  mat-icon-button [tabIndex]="2" (keyup.enter)="changeTab(row)" *ngIf="row.isattendance && enable != row.attendanceid">
                                <mat-icon  class="mr-1"  title="Attendance" (click)="setAttendanceRegularization(row)" >visibility</mat-icon>
                                </button> -->
                  <!-- <button  mat-icon-button [tabIndex]="2" (keyup.enter)="changeTab(row)" *ngIf="row.isleave && enable != row.attendanceid">
                                    <mat-icon  class="mr-1"  title="Leave" (click)="setLeaveRegularization(row)"  >comment</mat-icon>
                                </button> -->

                  <button [disabled]="btnDisable" [ngClass]="{'disable':btnDisable}" mat-icon-button [tabIndex]="2"
                    (keyup.enter)="changeTab(row)" *ngIf="enable === row.attendanceid">
                    <mat-icon class="mr-1" title="Save" (click)="saveRegularization(row)">save</mat-icon>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="leaveRequest">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <a style="padding-left: 5px;padding-right: 10px;" matTooltip="Attendance request"
                    (click)="setAttendanceRegularization(row)" *ngIf="row.isattendance && enable != row.attendanceid">
                    <img src="assets\img\attendenceblack.png" style="height: 28px" />
                  </a>

                  <a style="padding-right: 10px;" matTooltip="Leave request" (click)="setLeaveRegularization(row)"
                    *ngIf="row.isleave && enable != row.attendanceid">
                    <img src="assets\img\leave-request-black.png" style="height: 35px" /></a>
                  <button mat-icon-button [tabIndex]="2" (keyup.enter)="changeTab(row)"
                    *ngIf="enable === row.attendanceid">
                    <mat-icon class="mr-1" title="Cancel" (click)="canceledit()">cancel</mat-icon>
                  </button>
                </td>

              </ng-container>

              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                  <div [@detailExpand]="source === element ? 'expanded' : 'collapsed'" style="overflow: hidden;">
                    <form [formGroup]="addNewRegularization" style="padding-top: 10px;">
                      <div fxLayout="row">
                        <div>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                            <mat-label>Date</mat-label>
                            <!-- <input matInput readonly required [min]="minFromDate" [max]="maxFromDate"
                              [matDatepicker]="fromDate" [matDatepickerFilter]="myDateFilter" placeholder="DD-MM-YYYY"
                              (click)="fromDate.open()" formControlName="date" [tabIndex]="3"> -->
                            <input matInput formControlName="date">
                            <mat-datepicker-toggle class="datepicker" disabled="true" matSuffix
                              [for]="fromDate"></mat-datepicker-toggle>
                            <mat-datepicker #fromDate></mat-datepicker>

                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                            <mat-label>Login time</mat-label>
                            <input matInput formControlName="addLogin" matTimepicker>
                            <mat-error class="con-error"
                              *ngIf="addNewRegularization.controls.addLogin.errors?.required">
                              This Field is required.

                            </mat-error>

                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                            <mat-label>Logout time</mat-label>
                            <input matInput formControlName="addLogout" matTimepicker>
                            <mat-error class="con-error"
                              *ngIf="addNewRegularization.controls.addLogout.errors?.required">
                              This Field is required.

                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div>
                          <button [disabled]="btnDisable" [ngClass]="{'disable':btnDisable}"
                            class="btn btn-primary mr-2 mb-2" style="padding: 0 10px; margin-top: 10px;"
                            [disabled]="btnDisable" (click)="addRegularization(element)">
                            <span>Submit</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </td>
              </ng-container>
              <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.expanded]="source === row"></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="detail-row"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
              </tr>
            </table>
          </form>
          <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>
      </div>
    </div>
  </mat-card>
</div>


<div fxLayout="column" class="first-col" *ngIf="isleaveadd">
  <form [formGroup]="leaveRequestForm" autocomplete="off">
    <mat-card>
      <mat-card-header>
        <mat-card-title> Attendance Regularaization </mat-card-title>
      </mat-card-header>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
        <mat-card fxFlex="60" style="padding-left: 1%">
          <div fxLayout="row" fxLayout.xs="column">
            <mat-form-field fxFlex="50" fxFlex.lt-lg="100" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
              <mat-label>Leave Types</mat-label>
              <mat-select formControlName="leaveTypeId" required>
                <ng-container>
                  <div *ngFor="let l of leavesTypeData">
                    <mat-option value="{{ l.value }}">{{ l.name }}</mat-option>
                  </div>
                </ng-container>
              </mat-select>
              <mat-error class="con-error" *ngIf="leaveRequestForm.controls.leaveTypeId.errors?.required">
                Please select an option
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column">
            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
              <mat-label>From Date </mat-label>
              <input formControlName="fromDate" maxlength="10" minlength="10" onlyNumber required matInput
                placeholder="" />
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutGap="10px">
            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
              <mat-label>To Date</mat-label>
              <input formControlName="toDate" maxlength="10" minlength="10" onlyNumber required matInput
                placeholder="" />
            </mat-form-field>

          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
            <mat-form-field fxFlex.xs="100" fxFlex="50" fxFlex.lt-lg="100" appearance="outline" class="mx-2 my-2">
              <mat-label>Leave Count</mat-label>
              <input formControlName="leaveCount" [attr.disabled]="true" [readonly]="true" required matInput
                placeholder="Leave Count" />
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px"></div>
          <div fxLayoutAlign="center">
            <button [disabled]="btnDisable" class="btn btn-primary mr-2 mb-2" style="padding: 0 10px"
              (click)="setApplyLeave()">
              <span>Submit</span>
            </button>
            <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
              <span>Cancel</span>
            </button>
            <br />
          </div>
        </mat-card>
      </div>
    </mat-card>
  </form>
</div>