<div fxLayout="column" class="first-col">
    <mat-card>
        <form [formGroup]="terminateForm" autocomplete="off">
            <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-card-title class="title"> Termination </mat-card-title>
                <div style="margin-right: 2%;" *ngIf="istable">
                    <button mat-icon-button style="padding-right: 40px;" (click)="terminate()" matTooltip="Terminate"
                        [tabIndex]="[1]" (keyup.enter)="terminate()">
                        <img (click)="terminate()" src="./assets/images/icons/terminate.png" style="width:30px ;">
                        <!-- <mat-icon style="font-size: 30px">add_circle</mat-icon> -->
                    </button>
                </div>
            </mat-card-header>

            <div *ngIf="isterminate" fxLayout="column">
                <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center"
                    class="dev-Pad">
                    <mat-form-field fxFlex.xs="100" fxFlex *ngIf="isview" class="mx-2 my-2" appearance="outline">
                        <mat-label>Employee Name</mat-label>
                        <mat-select required formControlName="empname" [disabled]="!isview" required>
                            <input matInput [formControl]="searchControl" type="text" placeholder="  Search"
                                class="search-align">
                            <ng-container>
                                <div *ngFor="let r of filteredEmp">
                                    <mat-option [value]="r.id">{{r.empid}}--{{ r.ename }}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="terminateForm.controls.empname.errors?.required">
                            Please select an option
                        </mat-error>
                        <!-- <input formControlName="empname" readonly matInput placeholder="" maxlength="100" > -->
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex *ngIf="!isview" class="mx-2 my-2" appearance="outline">
                        <mat-label>Employee Name</mat-label>
                        <input formControlName="empname" matInput placeholder="" [readonly]="!isview" />
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Date of Termination </mat-label>
                        <input matInput readonly [min]="minFromDate" [max]="max" [matDatepicker]="date"
                            placeholder="MM/DD/YYYY" formControlName="dateoftermination" required
                            (click)="date.open()" />
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="date">
                        </mat-datepicker-toggle>
                        <mat-datepicker #date></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
                        <mat-label>Termination Category</mat-label>
                        <mat-select required formControlName="terminatecategory" [disabled]="!isview" required>
                            <ng-container>
                                <div *ngFor="let r of terminationlist">
                                    <mat-option [value]="r.id">{{ r.category }}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="terminateForm.controls.terminatecategory.errors?.required">
                            Please select an option
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" class="dev-Pad">
                    <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                        <mat-label>Reason</mat-label>
                        <textarea type="text" formControlName="reason" [readonly]="!isview" matInput placeholder=""
                            maxlength="250">
                         </textarea>
                    </mat-form-field>
                </div>
                <br>
                <div fxLayoutAlign="center">
                    <button class="btn btn-primary custom mr-2 mb-2" style="padding: 0 10px" [disabled]="btnDisable"
                        [ngClass]="{'disable':btnDisable}" (click)="terminatesave()" *ngIf="isview">
                        <span>Terminate</span>
                    </button>
                    <button class="btn btn-danger mr-2 mb-2" (click)="close()">
                        <span>Close</span>
                    </button>
                    <br />
                </div>
                <br />
            </div>

            <div fxLayout="column" *ngIf="istable">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" class="dev-Pad" style="height: 70px;">
                    <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2"
                        appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
                        <mat-label>Search </mat-label>
                        <input formControlName="searchempname" (keyup)="applyFilter($event)" [tabIndex]="[2]"
                            (keyup.enter)="applyFilter($event)" matInput placeholder="" maxlength="100" />
                    </mat-form-field>
                </div>
                <div fxLayout="column" class="table-pad">
                    <div class="mat-elevation-z1 example-container">
                        <table mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef>S. No.</th>
                                <td mat-cell *matCellDef="let element; let i = index">
                                    {{ i + 1 + paginator.pageIndex * paginator.pageSize }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="empid">
                                <th mat-header-cell *matHeaderCellDef class="no-break">Emp. ID</th>
                                <td mat-cell *matCellDef="let row" class="no-break">
                                    <span>{{ row.empid }} </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="fullname">
                                <th mat-header-cell *matHeaderCellDef class="no-break">Emp. Name</th>
                                <td mat-cell *matCellDef="let row" class="no-break">
                                    <span>{{ row.empname }} </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="manager">
                                <th mat-header-cell *matHeaderCellDef>Manager</th>
                                <td mat-cell *matCellDef="let row" class="no-break">
                                    <span>{{ row.managername }} </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="terminatedate">
                                <th mat-header-cell *matHeaderCellDef class="no-break">Terminate Date</th>
                                <td mat-cell *matCellDef="let row" class="no-break">
                                    <!-- <span >{{row.date}} | date:'mediumDate'</span> -->
                                    <span *ngIf="enable != row.id">{{ row.termination_date | date : "dd-MM-yyyy" }}
                                    </span>

                                    <mat-form-field appearance="outline" *ngIf="enable === row.id">
                                        <input matInput readonly [matDatepicker]="date1" placeholder="MM/DD/YYYY"
                                            formControlName="editdate" />
                                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="date1">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #date1></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.status }} </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Action">
                                <th mat-header-cell *matHeaderCellDef></th>

                                <td mat-cell *matCellDef="let row">
                                    <button mat-icon-button [tabIndex]="[3]" (keyup.enter)="view($event, row)">
                                        <mat-icon title="View" (click)="view($event, row)">visibility</mat-icon>
                                    </button>

                                    <!-- <mat-icon title="Edit"  (click)="edit($event,row)" *ngIf="enable != row.id && row.status != 'Terminated'" >edit</mat-icon> -->
                                    <!-- <mat-icon *ngIf="enable != row.id">more_vert</mat-icon> -->
                                    <!-- <mat-icon title="Terminated" *ngIf="enable != row.id && row.status == 'Taskcompleted'">check_circle</mat-icon> -->
                                    <!-- <mat-icon title="Revoke Termination"*ngIf="enable != row.id && row.status != 'Terminated'" (click)="revoke($event,row)">restore</mat-icon>
                                <mat-icon title="Save" *ngIf="enable == row.id" (click)="save($event,row)">save</mat-icon>
                                <mat-icon title="Save" *ngIf="row.status == 'enable != row.id && Taskcompleted' || row.status == 'Terminated' " (click)="view($event,row)" >visibility</mat-icon>
                                <mat-icon title="Cancel" (click)="canceledit($event,row.id)" *ngIf="enable == row.id" >cancel</mat-icon> -->
                                </td>
                            </ng-container>

                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="5" style="text-align: center">
                                    No data found
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                    </div>
                </div>
            </div>
        </form>
    </mat-card>
</div>