<div fxLayout="column" class="first-col">
  <mat-card>
    <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title class="title"> Work Location </mat-card-title>
      <div style="margin-right: 2%;" *ngIf="isdata">
        <button mat-icon-button style="padding-right: 40px;" (click)="Add()" matTooltip="Add New">
          <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <form [formGroup]="worklocationForm" autocomplete="off" *ngIf="isadd">

      <div *ngIf="ishide">
        <mat-card class="tableP">
          <table style="color: red; font-size: medium">
            <tr>
              Please follow these instructions while adding a location.
            </tr>
            <tr>
              1. Provide SAME prefix and seed values if you need common
              employee-id generation across all branches.
            </tr>
            <tr>
              2. Provide DIFFERENT prefix and seed values if you need separate
              employee-id generation for each branch.
            </tr>
            <tr>
              3. Prefix is a character string which is same for all employees of
              a location. Ex: 'GOGL'
            </tr>
            <tr>
              4. Seed value is the value you want employee-id generation to
              start from.
            </tr>
            <tr>
              5. Branch Location - short name, for ex. Kukatpally - like KP
            </tr>
          </table>
          <mat-icon (click)="location()" fxLayoutAlign="end" style="padding-left: 100%;color: green;">
            location_on</mat-icon>
        </mat-card>
      </div>
      <br>
      <div class="tableP">
        <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxLayoutAlign.xs="center center">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Address1</mat-label>
            <input formControlName="address1" matInput placeholder="" maxlength="100" />

          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Address2</mat-label>
            <input type="text" formControlName="address2" matInput placeholder="" maxlength="100" />
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>Branch Location</mat-label>
            <input formControlName="branch" matInput placeholder="" maxlength="100" required />
            <mat-error class="con-error" *ngIf="worklocationForm.controls.branch.errors?.required">
              {{ msgEM1 }}
            </mat-error>
          </mat-form-field>

        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxLayoutAlign.xs="center center">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Country</mat-label>
            <mat-select [disabled]="isview" required formControlName="country">
              <ng-container>
                <div *ngFor="let b of CountryDetails">
                  <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                  <mat-option [value]="b.id">{{ b.country }}</mat-option>
                </div>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="worklocationForm.controls.country.errors?.required">
              {{ msgEM2 }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>State</mat-label>
            <mat-select [disabled]="isview" required formControlName="state">
              <ng-container>
                <div *ngFor="let b of stateDetails">
                  <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                  <mat-option [value]="b.id">{{ b.state }}</mat-option>
                </div>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="worklocationForm.controls.state.errors?.required">
              {{ msgEM2 }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>City</mat-label>
            <mat-select [disabled]="isview" required formControlName="city">
              <ng-container>
                <div *ngFor="let b of CityDetails">
                  <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                  <mat-option [value]="b.id">{{ b.location }}</mat-option>
                </div>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="worklocationForm.controls.city.errors?.required">
              {{ msgEM2 }}
            </mat-error>
          </mat-form-field>
        </div>
        <div xLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxLayoutAlign.xs="center center">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Pincode</mat-label>
            <input type="text" onlyNumber formControlName="pincode" matInput placeholder="" minlength="6"
              maxlength="6" />
            <mat-error class="con-error" *ngIf="
              worklocationForm.controls.pincode.errors?.maxlength ||
              worklocationForm.controls.pincode.errors?.minlength
            ">
              This field length should be 6 digits
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Prefix</mat-label>
            <input formControlName="prefix" matInput placeholder="" maxlength="16" />
          </mat-form-field>
          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>Seed</mat-label>
            <input type="text" onlyNumber formControlName="seed" matInput placeholder="" required maxlength="10" />
            <mat-error class="con-error" *ngIf="worklocationForm.controls.seed.errors?.required">
              {{ msgEM1 }}
            </mat-error>
          </mat-form-field>
        </div>
        <div xLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxLayoutAlign.xs="center center">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Longitude</mat-label>
            <input type="text" formControlName="longitude" matInput placeholder="" />
          </mat-form-field>
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Latitude</mat-label>
            <input formControlName="latitude" [readonly]="true" matInput placeholder="" />
          </mat-form-field>
          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>Measure(in meters)</mat-label>
            <input type="text" onlyNumber formControlName="measure" matInput placeholder="" required maxlength="3" />
            <mat-error class="con-error" *ngIf="worklocationForm.controls.measure.errors?.required">
              {{ msgEM1 }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayoutAlign="center">
          <span *ngIf="isnote" style="color: red">Note: As the entered prefix already exists, seed value need not be
            entered.</span>
        </div>
        <br />
        <div fxLayoutAlign="center">
          <button class="btn btn-primary mr-2 mb-2" [disabled]="btndisabled" [ngClass]="{'disable':btndisabled}"
            (click)="submit()" type="submit" *ngIf="!isview">
            <span>Submit</span>
          </button>

          <button class="btn btn-danger mr-2 mb-2" (click)="cancel()" *ngIf="!isview">
            <span>Cancel</span>
          </button>
          <button class="btn btn-danger mr-2 mb-2" (click)="close()" *ngIf="isview">
            <span>Close</span>
          </button>
        </div>
      </div>

      <br>
    </form>
    <div *ngIf="isdata" fxLayout="column">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" class="dev-Pad">
        <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
          <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
          <mat-label>Search </mat-label>
          <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100" />
        </mat-form-field>
      </div>
      <div fxLayout="column" class="table-pad">
        <div class="mat-elevation-z1 example-container">
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef>S. No.</th>
              <td mat-cell *matCellDef="let element; let i = index">
                {{ i + 1 + paginator.pageIndex * paginator.pageSize }}
              </td>
            </ng-container>

            <ng-container matColumnDef="city-branch">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> City-Branch </th>
              <td mat-cell *matCellDef="let row">
                <span>{{ row.cityname }} {{ row.location }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="prefix">
              <th mat-header-cell *matHeaderCellDef>Prefix</th>

              <td mat-cell *matCellDef="let row">
                <span>{{ row.prefix }}&nbsp;</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="seed">
              <th mat-header-cell *matHeaderCellDef>Seed</th>

              <td mat-cell *matCellDef="let row">
                <span>{{ row.seed }}&nbsp;</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef style="padding-left: 60px">
                Status
              </th>

              <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                <mat-radio-group>
                  <mat-radio-button class="mr-2" color="primary" *ngFor="let halfs of arrayValue; index as i"
                    (change)="status(halfs.id, row.id)" [checked]="halfs.id == row.status" [value]="halfs.id">{{
                    halfs.name }}</mat-radio-button>
                </mat-radio-group>
              </td>
            </ng-container>

            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef></th>

              <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                <button mat-icon-button (keyup.enter)="edit(row)">
                  <mat-icon (click)="edit(row)" title="Edit">edit</mat-icon>
                </button>
                <button mat-icon-button (keyup.enter)="view(row)">
                  <mat-icon (click)="view(row)" title="View">visibility</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="6" style="text-align: center">
                No data found
              </td>
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>
      </div>
    </div>
  </mat-card>
</div>