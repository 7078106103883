<form fxLayout="column" [formGroup]="searchForm" style="margin-top: 10px">
  <div fxLayout="row" fxLayout.lt-lg="column">
    <div fxLayout="row" fxFlex="50" fxLayout.xs="column">
      <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
        <mat-label>Employee Name and ID</mat-label>
        <mat-select formControlName="employees" tabindex="1">
          <input class="search-align" type="text" placeholder="Search" [formControl]="searchControl" matInput />
          <mat-option value="0"> All Employees </mat-option>
          <mat-option *ngFor="let list of filteredEmployees" [value]="list.empid">{{ list.empname }} -- {{
            list.empcode }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
        <mat-label>Month and Year</mat-label>
        <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate" placeholder=""
          (click)="fromDate.open()" formControlName="fromDate" />
        <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate">
        </mat-datepicker-toggle>
        <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)">
        </mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="50" fxLayout="row" fxLayoutAlign="end">
      <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabIndex="2">
        Search
      </button>
      <span class="line" style="color: #1898d5; font-size: 16px"></span>
      <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabIndex="3">
        <span>Clear</span>
      </button>
    </div>

    <div fxFlex="50" fxLayoutAlign="end">
      <div style="padding-right: 2%">
        <button mat-icon-button>
          <a title="Excel" [tabIndex]="5" (keyup.enter)="exportAsXLSX()">
            <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png" />
          </a>
        </button>
      </div>
    </div>
  </div>
  <table class="rTable" *ngIf="List.length >= 0">
    <thead class="rHeader">
      <tr>
        <th class="red-icon">A - Absent</th>
        <th class="green-icon">P - Present</th>
        <th class="blue-icon">W - Week-Off</th>
        <th class="orange-icon">L -Leave</th>
        <th class="yellow-icon">H - Holiday</th>
        <th class="light-icon">HD - Half Day</th>
        <th class="wp-icon">WP - Week-off Present</th>
        <th class="hp-icon">HP - Holiday Present</th>
      </tr>
    </thead>
  </table>
  <br>
  <!-- <p *ngIf="List.length > 0" style="color:red;margin-left: 2%;" >*Note: Absent,Week-Off,Leave,Holiday, Single Punch and Current day punch details are not shown in Details popup.</p>
     
      <div class="p-2">
        <div class="example-container" style="padding: 10px" #table>
          <div style="display: flex; overflow-x: auto;max-height: 500px;">
            <table style="width: 100%" *ngIf="List.length > 0" id="table">
              <thead class="theader" style="position: sticky; top: 0; z-index: 1; background-color:#28acaf;">
                <tr>
                  <th style="text-wrap: nowrap"><span>S. No. </span></th>
                  <th style="text-wrap: nowrap"><span>Emp. Code </span></th>
                  <th style="text-wrap: nowrap"><span>Emp. Name </span></th>
                  <ng-container *ngFor="let a of headersList[0]; let i = index">
                    <th>
                      <u>{{ a | date : "d" }} </u><br />
                      {{ headersList[1][i] | limit : 2 }}
                    </th>
                  </ng-container>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of List">
                  <tr style="width: auto">
                    <ng-container *ngFor="let e of item">
                      <ng-container *ngIf="isArray(e)">
                        <td>
                          <ng-container *ngFor="let t of e">
                            <p [ngStyle]="{ color: getColor(t) }" style="margin: 0">
                              <b>{{ t }}</b>
                            </p>
                          </ng-container>
                        </td>
                      </ng-container>

                      <ng-container *ngIf="!isArray(e)">
                        <td [ngStyle]="{ color: getColor(e) }">
                          <b>{{ e }}</b>
                        </td>
                      </ng-container>
                    </ng-container>
                    <td>
                      <button mat-icon-button (click)="openDialog(item)" [tabIndex]="8">
                        <a style="color: blue">
                          <u>Details</u>
                        </a>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table> -->
  <p *ngIf="List.length > 0" style="color:red;margin-left: 2%;">*Note: Absent,Week-Off,Leave,Holiday, Single Punch
    and Current day punch details are not shown in Details popup.</p>

  <div class="p-2">
    <div class="example-container" style="padding: 10px" #table>
      <div style="display: flex; overflow-x: auto;max-height: 500px;">

        <table class="dataTable" style="width: 100%" *ngIf="List.length > 0" id="table">
          <thead class="theader">
            <tr>
              <th class="sticky-col sticky-header" style="left: 0;">S. No.</th>
              <th class="sticky-col sticky-header" style="left: 80px;">Emp. Code</th>
              <th class="sticky-col sticky-header" style="left: 180px;">Emp. Name</th>
              <ng-container *ngFor="let a of headersList[0]; let i = index">
                <th class="sticky-header" style=" width: 60px;">
                  <u>{{ a | date : "d" }}</u><br />
                  {{ headersList[1][i] | limit : 2 }}
                </th>
              </ng-container>
              <th class="sticky-header" style=" width: 60px;"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of List">
              <tr>
                <td class="sticky-col " style="left: 0;">
                  <b>{{ item[0] }}</b>
                </td>
                <td class="sticky-col" style="left: 80px;">
                  <b>{{ item[1] }}</b>
                </td>
                <td class="sticky-col" style="left: 180px;">
                  <b>{{ item[2] }}</b>
                </td>
                <ng-container *ngFor="let e of item.slice(3)">
                  <td>
                    <ng-container *ngIf="isArray(e); else singleValue">
                      <ng-container *ngFor="let t of e">
                        <p [ngStyle]="{ color: getColor(t) }" style="margin: 0;">
                          <b>{{ t }}</b>
                        </p>
                      </ng-container>
                    </ng-container>
                    <ng-template #singleValue>
                      <b [ngStyle]="{ color: getColor(e) }">{{ e }}</b>
                    </ng-template>
                  </td>
                </ng-container>
                <td>
                  <button mat-icon-button (click)="openDialog(item)" [tabIndex]="8">
                    <a style="color: blue">
                      <u>Details</u>
                    </a>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div style="text-align: center; padding-top: 20px" *ngIf="List.length === 0">
        No data found.
      </div>
    </div>
  </div>
</form>