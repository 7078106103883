<div fxLayout="column" class="first-col">
    <mat-card>
        <form [formGroup]="reasonsForm" autocomplete="off">
            <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-card-title class="title"> Reason for Separation </mat-card-title>
                <div style="margin-right: 2%;" *ngIf="isdata">
                    <button mat-icon-button style="padding-right: 40px;" (click)="Add()" matTooltip="Add New">
                        <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                    </button>
                </div>
            </mat-card-header>
            <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
                <div fxLayout="row" class="tableP">
                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                        <mat-label>Reason</mat-label>
                        <input formControlName="reason" matInput placeholder="" onlyAlphabets appRemoveSpace required
                            maxlength="100" />
                        <mat-error class="con-error"
                            *ngIf="reasonsForm.controls.reason.errors?.required || reasonsForm.controls.reason.errors?.['whitespace']">
                            {{requiredField}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayoutAlign="center">
                    <button class="btn btn-primary mr-2 mb-2" [disabled]="btnDisable" [ngClass]="{'disable':btnDisable}"
                        (click)="saveReason()" type="submit">
                        <span>Submit</span>
                    </button>

                    <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>
            </div>
            <div fxLayout="column" *ngIf="isdata">
                <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
                    <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
                        <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2"
                            appearance="outline">
                            <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                            <mat-label>Search</mat-label>
                            <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
                        </mat-form-field>
                    </span>
                </div>
                <div fxLayout="column" class="table-pad">
                    <div class="mat-elevation-z1 example-container">
                        <table mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef>S. No.</th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                            </ng-container>
                            <ng-container matColumnDef="termination">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Reason</th>
                                <td mat-cell *matCellDef="let row">
                                    <span *ngIf="enable != row.id">{{row.reason}} </span>
                                    <span *ngIf="enable === row.id">
                                        <mat-form-field appearance="outline">
                                            <input formControlName="reason" matInput placeholder="{{row.reason}}"
                                                noLeadingWhitespace onlyAlphabets maxlength="100">
                                            <mat-error class="con-error"
                                                *ngIf="reasonsForm.controls.reason.errors?.required">
                                                {{requiredField}}
                                            </mat-error>
                                        </mat-form-field>
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef style="padding-left: 60px">
                                    Status
                                </th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                    <mat-radio-group>
                                        <mat-radio-button class="mr-2" color="primary"
                                            *ngFor="let list of statusList; index as i"
                                            (change)="statusUpdate(list.id, row)" [checked]="list.id == row.status"
                                            [value]="list.id">{{ list.name }}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let row" class="no-break">
                                    <button mat-icon-button matTooltip="Edit" *ngIf="enable != row.id"
                                        (keyup.enter)="edit($event,row)">
                                        <mat-icon (click)="edit($event, row)">edit</mat-icon>
                                    </button>

                                    <button mat-icon-button matTooltip="Save" *ngIf="enable == row.id"
                                        (keyup.enter)="updateData(reasonsForm.controls.reason.value,row)">
                                        <mat-icon (click)="updateData(reasonsForm.controls.reason.value,row)">
                                            save</mat-icon>
                                    </button>

                                    <button mat-icon-button *ngIf="enable == row.id" matTooltip="Cancel"
                                        (keyup.enter)="canceledit($event, row.id)">
                                        <mat-icon (click)="canceledit($event, row.id)" *ngIf="enable == row.id">cancel
                                        </mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4" style="text-align: center;">No data found</td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                    </div>
                </div>
            </div>
        </form>
    </mat-card>
</div>