<div fxLayout="column" class="first-col">
  <mat-card>
    <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title class="title"> New Hires </mat-card-title>
      <div style="margin-right: 2%;" *ngIf="isNewhireList">
        <button mat-icon-button style="padding-right: 40px;" (click)="newHire()" matTooltip="Add New">
          <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
        </button>
      </div>
    </mat-card-header>

    <div fxLayout="column" *ngIf="isNewhire" class="main-Pad">
      <form [formGroup]="hireForm" autocomplete="off">
        <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center">

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>First Name</mat-label>
            <input formControlName="firstname" class="m_title" matInput placeholder="" required onlyAlphabets
              maxlength="64" noLeadingWhitespace>
            <mat-error class="con-error" *ngIf="hireForm.controls.firstname.errors?.required ||
            hireForm.controls.firstname.errors?.['whitespace']">
              {{requiredField}}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Middle Name</mat-label>
            <input formControlName="middlename" matInput placeholder="" noLeadingWhitespace onlyAlphabets
              maxlength="64">
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input formControlName="lastname" required matInput noLeadingWhitespace placeholder="" onlyAlphabets
              maxlength="64">
            <mat-error class="con-error" *ngIf="hireForm.controls.lastname.errors?.required
            ||
            hireForm.controls.lastname.errors?.['whitespace']">
              {{requiredField}}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxLayoutAlign.xs="center">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Personal Email</mat-label>
            <input formControlName="email" matInput placeholder="" maxlength>
            <mat-error class="con-error" *ngIf="hireForm.controls.email.errors?.email ||
            hireForm.controls.email.errors?.pattern">
              Please enter valid email
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Hired On</mat-label>
            <input matInput readonly required [matDatepicker]="hiredon" [min]="minDate" [max]="maxDate"
              placeholder="DD-MM-YYYY" formControlName="hiredon" (click)="hiredon.open()">
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="hiredon"></mat-datepicker-toggle>
            <mat-datepicker #hiredon></mat-datepicker>
            <mat-error class="con-error" *ngIf="hireForm.controls.hiredon.errors?.required">
              {{requiredOption}}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>Joining Date</mat-label>
            <input matInput readonly required [matDatepicker]="dateofjoin" [min]="minHireDate" [max]="joinDate"
              placeholder="DD-MM-YYYY" formControlName="dateofjoin" (click)="dateofjoin.open()">
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="dateofjoin"></mat-datepicker-toggle>
            <mat-datepicker #dateofjoin></mat-datepicker>
            <mat-error class="con-error" *ngIf="hireForm.controls.dateofjoin.errors?.required">
              {{requiredOption}}
            </mat-error>
          </mat-form-field>

        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxLayoutAlign.xs="center">

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Designation</mat-label>
            <mat-select formControlName="designation" required>
              <ng-container>
                <span *ngFor="let list of designationsList">
                  <mat-option [value]="list.id">{{list.designation}}</mat-option>
                </span>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="hireForm.controls.designation.errors?.required">
              {{requiredOption}}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Mobile Number</mat-label>
            <input formControlName="mobile" maxlength="10" onlyNumber required matInput>
            <mat-error class="con-error" *ngIf="hireForm.controls.mobile.errors?.required">
              {{requiredField}}
            </mat-error>
            <mat-error class="con-error" *ngIf="hireForm.get('mobile').hasError('pattern')">
              Please enter valid mobile number
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>Alternate Number</mat-label>
            <input formControlName="alternatenumber" matInput required maxlength="10" onlyNumber>
            <mat-error class="con-error" *ngIf="hireForm.controls.alternatenumber.errors?.required">
              {{requiredField}}
            </mat-error>
            <mat-error class="con-error" *ngIf="hireForm.get('alternatenumber').hasError('pattern')">
              Please enter valid mobile number
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="center">

          <button [disabled]="btnDisable" *ngIf="!isUpdate" class="btn btn-primary mr-2 mb-2" (click)="submit()" type="submit">
            <span>Submit</span>
          </button>
          <button [disabled]="btnDisable" *ngIf="isUpdate" class="btn btn-primary mr-2 mb-2" (click)="submit()" type="submit">
            <span>Update</span>
          </button>

          <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
            <span>Cancel</span>
          </button>
          <br />

        </div>

      </form>
    </div>

    <div *ngIf="isNewhireList">

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" class="dev-Pad"
        fxLayoutAlign="space-between center">
        <span fxFlex="50">
          <mat-radio-group (change)="radioChange($event)">
            <mat-radio-button value="1" [checked]="true"><b>Hired</b></mat-radio-button>&nbsp;
            <mat-radio-button value="2"><b>Joined</b></mat-radio-button>
          </mat-radio-group>
        </span>
        <span style="padding-top: 5px;height: 70px;" fxFlex="50" fxLayoutAlign="end">
          <mat-form-field fxFlex="56" fxFlex.xs="100" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
            <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
            <mat-label>Search</mat-label>
            <input (keyup)="applyFilter($event)" [tabIndex]="2" matInput placeholder="" maxlength="100">
          </mat-form-field>
        </span>
      </div>
      <div fxLayout="column" class="table-pad">
        <div class="example-container mat-elevation-z1">
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef>S. No.</th>
              <td mat-cell *matCellDef="let element; let i=index;">
                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Emp. Name </th>
              <td mat-cell *matCellDef="let row" class="truncate-cell">
                <span>{{row.firstname |titlecase}}{{' '}}{{row.middlename |titlecase}}{{' '}}{{row.lastname |titlecase}}
                </span>

              </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Personal Email ID </th>
              <td mat-cell *matCellDef="let row">
                <span>{{row.personal_email}} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="hireDate">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Hired Date </th>
              <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                <span>{{row.hired_date | date:'dd-MM-yyyy'}} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="joinDate">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Joining Date </th>
              <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                <span>{{row.dateofjoin | date:'dd-MM-yyyy'}} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="mobile">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Mobile Number</th>
              <td mat-cell *matCellDef="let row">
                <span>{{row.contact_number}} </span>

              </td>
            </ng-container>




            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let row">
                <span>{{row.status}} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row;let i = index;" style="white-space: nowrap;">
                <button mat-icon-button matTooltip="Edit" [tabindex]="[i+3]" (keyup.enter)="editEmployee($event,row)"
                  *ngIf="row.status == 'Hired' ">
                  <mat-icon (click)="editEmployee($event,row)">edit</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Joining Formalities" *ngIf="row.status == 'Hired' ">
                  <mat-icon (click)="editCandidateData($event,row)">save</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>
      </div>
    </div>
  </mat-card>
</div>