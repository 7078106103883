<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Employee Pay Groups </mat-card-title>
        </mat-card-header>
        <div fxLayout="column">
            <form [formGroup]="EmployeePayGroupForm" autocomplete="off" style="padding-left: 1%;padding-top: 1%;">
                <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center">
                    <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
                        <mat-label>Employee Name and Code </mat-label>
                        <input [tabIndex]="1" matInput placeholder="" maxlength="100" formControlName="employeeName"
                            (keyup)="applyFilter($event)" />

                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
                        <mat-label>Location</mat-label>
                        <mat-select formControlName="location" [tabIndex]="1" required>
                            <input matInput [formControl]="searchControlForLocation" type="text" placeholder="  Search"
                                class="search-align">
                            <ng-container>
                                <div *ngFor="let b of filteredLocationList">
                                    <mat-option [value]="b.id">{{ b.location }} - {{ b.cityname }}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="EmployeePayGroupForm.controls.location.errors?.required">
                            Please select an option
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
                        <mat-label>Pay Group</mat-label>
                        <mat-select formControlName="payGroup" [tabIndex]="2" required>
                            <input matInput [formControl]="searchControlForPayGroup" type="text" placeholder="  Search"
                                class="search-align">
                            <ng-container>
                                <div *ngFor="let a of filteredPayGroup">
                                    <mat-option [value]="a.id">{{ a.group_name}}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="EmployeePayGroupForm.controls.payGroup.errors?.required">
                            Please select an option
                        </mat-error>
                    </mat-form-field>
                </div>
                <br>
                <div fxLayout="row" fxLayoutAlign="center">
                    <div>
                        <button class="btn btn-primary mr-2 mb-2" type="submit" (click)="search()">Search</button>
                        <button class="btn btn-danger mr-2 mb-2" (click)="clear()">Clear</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="tableP">
            <div class="mat-elevation-z1 example-container">
                <table mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> <span style="text-wrap: nowrap;">S.
                                No.</span> </th>
                        <td mat-cell *matCellDef="let row;let i = index;">{{i+1}} </td>
                    </ng-container>

                    <ng-container matColumnDef="empcode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Emp. Code
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.empcode}}</td>
                    </ng-container>

                    <ng-container matColumnDef="empname">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Emp. Name
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
                    </ng-container>

                    <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">
                            Location </th>
                        <td mat-cell *matCellDef="let row"> {{row.location}}</td>
                    </ng-container>

                    <ng-container matColumnDef="deptname">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Department Name</th>
                        <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                            {{row.deptname}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="designation">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Designation</th>
                        <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.designation}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="paygroup">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Pay Group</th>
                        <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.paygroup}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ctc">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> CTC</th>
                        <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.ctc}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="assignedon">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Assigned On</th>
                        <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.assignedon |
                            date:'dd-MM-yyyy'}}
                        </td>
                    </ng-container>

                    <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
                    </tr>
                </table>
                <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>
        </div>


    </mat-card>

</div>