import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AttendanceService } from 'src/app/modules/attendance/attendance.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogCurrentdaypunchdetailsComponent } from '../dialog-currentdaypunchdetails/dialog-currentdaypunchdetails.component';
import { MatSelect } from '@angular/material/select';
import { ReportsService } from '../../reports.service';
import { EmsService } from 'src/app/modules/ems/ems.service';
import * as XLSX from 'xlsx';
interface EmployeePunchDetail {
  empname: string;
  empid: string;
  punchtimes: Date[];
  categories: string[];
  attendanceSource: string[];
  location: string[];
}

@Component({
  selector: 'app-currentday-attendance-report',
  templateUrl: './currentday-attendance-report.component.html',
  styleUrls: ['./currentday-attendance-report.component.scss']
})
export class CurrentdayAttendanceReportComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Input() screenForReportUser: boolean = false;



  displayedColumns: string[] = ['sno', 'empcode', 'empname', 'punchtimes', 'categories', 'attendanceSource', 'location', 'action'];
  dataSource: MatTableDataSource<any> = <any>[];
  date: any = '';
  uniqueLocations: string[] = [];
  selectedLocation: string = 'All Locations';
  searchQuery: string = '';
  searchControl = new FormControl();
  filteredLocations: any = [];
  worklocationDetails: any = [];
  companyName: any = [];
  searchForm: any = UntypedFormGroup;
  pageLoading = true;
  userSession: any;
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  selectedLocations: any = [];
  employeesDetails: any = [];
  filteredEmployeelist: any = [];
  employeeList: any = [];
  location_id: any;
  List: any = [];
  searchControlforEmp = new FormControl();
  constructor(
    private companyService: CompanySettingService,
    private formBuilder: FormBuilder,
    private attendanceService: AttendanceService,
    private dialog: MatDialog,
    private reportsService: ReportsService,
    private emsService: EmsService
  ) {
    this.date = new Date().toLocaleDateString();
  }

  ngOnInit(): void {

    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.companyName = sessionStorage.getItem("companyName");

    this.searchForm = this.formBuilder.group({
      employee: ['', Validators.required]
    });

    this.getEmployeelist();

    this.searchControlforEmp.valueChanges.subscribe((searchText: any) => {
      this.filterEmployee(searchText)
    });
    this.searchForm.controls.employee.setValue('0')
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyCombinedFilter(): void {
    this.dataSource.filter = JSON.stringify({ location: this.selectedLocation, query: this.searchQuery });
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  customFilterPredicate(): (data: EmployeePunchDetail, filter: string) => boolean {
    return (data: EmployeePunchDetail, filter: string): boolean => {
      const filterObj = JSON.parse(filter);
      const locationMatch = filterObj.location === 'All Locations' || data.location.includes(filterObj.location);
      const nameMatch = !filterObj.query || data.empname.toLowerCase().includes(filterObj.query.toLowerCase());
      return locationMatch && nameMatch;
    };
  }

  getSerialNumber(index: number): number {
    return index + 1 + (this.paginator ? this.paginator.pageIndex * this.paginator.pageSize : 0);
  }

  filterLocations(searchText: any) {
    this.filteredLocations = this.worklocationDetails.filter((val: any) =>
      val.location.toLowerCase().includes(searchText.toLowerCase()) || val.cityname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredLocations.length <= 0) {
      this.searchControl.setValue('');
    }
  }

  getDetails() {
    if (this.searchForm.valid) {
      let data = {
        location: this.location_id,
        employeeId: this.searchForm.controls.employee.value,
      }
      this.attendanceService.getCurrentdayAttendanceSummaryReport(data).subscribe((result: any) => {
        this.List = result.data;
        this.dataSource = new MatTableDataSource(this.List);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
        // this.location_id = [];
      })
    }
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);

    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  openDialog(item: any): void {
    const dialogRef = this.dialog.open(DialogCurrentdaypunchdetailsComponent, {
      width: '1200px', position: { top: `70px` },
      data: { attendanceid: item.attendanceid, }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  getEmployeelist() {
    if (!this.screenForReportUser) {
      let obj = {
        "rm_id": this.userSession.id,
      };
      this.reportsService.getTotalEmployeslistByManagerId(obj).subscribe((res: any) => {
        if (res.status) {
          this.employeeList = [];
          this.employeeList = res.data;
          this.filteredEmployeelist = this.employeeList,
            this.searchForm.controls.employee.setValue('0');
        }
      });
    } else {
      let obj = {
        remployee_id: this.userSession.id,
      };
      this.reportsService
        .getReportsuserEmployeesList(obj)
        .subscribe((res: any) => {
          if (res.status) {
            this.employeeList = [];
            this.employeeList = res.data;
            this.filteredEmployeelist = this.employeeList;
            this.searchForm.controls.employee.setValue('0');
          }
        });
    }


  }
  resetform() {

    this.searchForm.controls.employee.setValue('0');
    this.filteredEmployeelist = [];
    this.List = [];
    this.dataSource = new MatTableDataSource(this.List);
  }

  filterEmployee(searchText: any) {
    this.filteredEmployeelist = this.employeeList.filter((val: any) =>
      val.empname.toLowerCase().includes(searchText.toLowerCase()) || val.empcode.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmployeelist.length <= 0) {
      this.searchControlforEmp.setValue('');
    }
  }

  downloadPDF() {

    let data = {
      'list': this.List,
      'report_date': this.date
    }
    this.reportsService.getAttendanceSummaryReportPdf(data).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'attendance_summary_report_for_manager.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  exportAsXLSX() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('table'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Attendance_Summary_Report');
    /* save to file */
    XLSX.writeFile(wb, 'Attendance_Summary_Report_for_manager.xlsx');

  }



}
