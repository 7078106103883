import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, ValidationErrors, ValidatorFn, AbstractControl, FormControl, } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AttendanceService } from '../../attendance.service';
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { ComfirmationDialogComponent } from 'src/app/pages/comfirmation-dialog/comfirmation-dialog.component';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-shift-change-request',
  templateUrl: './shift-change-request.component.html',
  styleUrls: ['./shift-change-request.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ShiftChangeRequestComponent implements OnInit {
  constructor(private formBuilder: UntypedFormBuilder, private router: Router, public datePipe: DatePipe,
    private adminService: AdminService, public spinner: NgxSpinnerService,
    private dialog: MatDialog, public attendanceService: AttendanceService) {

  }
  shiftRequestForm: any = UntypedFormGroup;
  userSession: any;
  pipe = new DatePipe('en-US');
  maxDate = new Date();
  minDate = new Date();
  toShiftList: any = []
  messagesDataList: any = [];
  requiredField: any;
  requiredOption: any;
  dataSave: any;
  dataNotSave: any;
  isvalid: boolean = false;
  EM43: any;
  EM55: any;
  companyDBName: any = environment.dbName;
  activeShiftsList: any = []
  displayedColumns: string[] = ['sno', 'fromshift', 'toshift', 'fromdate', 'todate', 'status', 'action'];
  dataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  isNew: boolean = false;
  isShow: boolean = true;
  isUpdate: boolean = false;
  isView: boolean = false;
  todayWithPipe: any;
  employeeId: any;
  fromShiftId: any;
  shiftDataList: any = [];
  minTodate: any;
  updateId: any;
  btnDisable: boolean = false;
  ATT77: any;
  ATT78: any;
  ATT79: any;
  ATT80: any;
  ATT81: any;
  ATT82: any;
  ATT83: any;
  ATT84: any;
  ATT85: any;
  ATT86: any;
  currentDate = new Date();
  searchControl = new FormControl('');
  filteredToShifts: any = [];
  disabled: boolean = false;
  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.employeeId = this.userSession.id;
    this.todayWithPipe = this.pipe.transform(Date.now());
    this.shiftRequestForm = this.formBuilder.group(
      {
        appliedDate: [{ value: new Date(), disabled: true }],
        currentShift: [{ value: "", disabled: true }],
        fromShift: [{ value: "", disabled: true }],
        toShift: ["", [Validators.required]],
        fromDate: ["", [Validators.required]],
        toDate: ["", [Validators.required]],
        reason: ["", [Validators.required]],

      });
    this.getMessagesList();
    this.getShiftRequestList();
    this.shiftRequestForm.get('fromDate')?.valueChanges.subscribe((selectedValue: any) => {
      this.minTodate = selectedValue._d;
      this.shiftRequestForm.controls.toDate.setValue('');
    })

    this.shiftRequestForm.get('toShift')?.valueChanges.subscribe((selectedValue: any) => {
      if (selectedValue == this.fromShiftId) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `100px` },
          disableClose: true,
          data: this.ATT81
        });
        this.shiftRequestForm.get('toShift').reset()
      }
    })

    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterList(searchText)
    })
  }

  newRequest() {
    this.isNew = true;
    this.isShow = false;
    this.isUpdate = false;
  }


  onChange() {
    this.btnDisable = false;
    let fromDate = this.datePipe.transform(this.shiftRequestForm.controls.fromDate.value, "y-MM-d");
    let toDate = this.datePipe.transform(this.shiftRequestForm.controls.toDate.value, "y-MM-d");
    let cdate = this.datePipe.transform(this.currentDate, "y-MM-d");
    if (fromDate == cdate) {
      this.getShiftsForChangeByEmpID();
    } else {
      this.getActiveShiftIds();
    }
    let data = {
      "empid": this.employeeId,
      "fromdate": fromDate,
      "todate": toDate,
    }
    this.attendanceService.getShiftsByEmpIdAndDates(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        let value = res.data[0];
        this.fromShiftId = value.shiftid;
        this.shiftRequestForm.controls.fromShift.setValue(value.shiftname);
        this.shiftRequestForm.get('toShift').reset();
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `100px` },
          disableClose: true,
          data: this.ATT82
        });
        this.btnDisable = true;
      }
    })
  }

  getActiveShiftIds() {
    this.attendanceService.getActiveShiftIds().subscribe((res) => {
      if (res.status) {
        this.activeShiftsList = res.data;
        this.filteredToShifts = this.activeShiftsList;
      }
    })
  }

  getShiftsForChangeByEmpID() {
    this.attendanceService.getShiftsForChangeByEmpID(this.employeeId).subscribe((res) => {
      if (res.status) {
        this.activeShiftsList = res.data;
        if (this.activeShiftsList.length == 0) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Please select next date"
          });
        }
      }
    })

  }

  submit() {
    if (this.btnDisable) {
      return;
    }
    if (this.shiftRequestForm.valid) {
      let fromDate = this.datePipe.transform(this.shiftRequestForm.controls.fromDate.value, "y-MM-d");
      let toDate = this.datePipe.transform(this.shiftRequestForm.controls.toDate.value, "y-MM-d");

      let data = {
        "id_value": this.updateId == null ? null : this.updateId,
        "empid_value": this.employeeId,
        "from_shiftid_value": this.fromShiftId,
        "to_shiftid_value": this.shiftRequestForm.controls.toShift.value,
        "fromdate_value": fromDate,
        "todate_value": toDate,
        "reason_value": this.shiftRequestForm.controls.reason.value,
        "raisedby_value": this.employeeId,
        "status_value": "Submitted",
      };
      this.btnDisable = true;
      this.spinner.show();
      this.attendanceService.setShiftChangeRequest(data).subscribe((res: any) => {
        if (res.status && res.data == 0) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.isUpdate == true ? this.ATT79 : this.ATT78
          });
          this.spinner.hide();
          this.resetForm();
        } else if (res.status && res.data == 1) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.ATT84
          });
          this.btnDisable = false;
          this.spinner.hide();
          this.resetForm();
        } else if (res.status && res.data == 2) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.ATT77
          });
          this.spinner.hide();
          this.resetForm();
          this.btnDisable = false;
        }
        else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.ATT83
          });
          this.spinner.hide();
          this.btnDisable = false;
        }
      })
    } else {
      this.spinner.hide();

    }
  }

  editRequest(id: any, data: any) {
    let cdate = this.datePipe.transform(this.currentDate, "y-MM-d");
    if (data.fromdate == cdate) {
      this.getShiftsForChangeByEmpID();
    } else {
      this.getActiveShiftIds();
    }


    this.isShow = false,
      this.isNew = true,
      this.isUpdate = true;
    this.updateId = data.id;
    this.employeeId = data.employee_id;
    this.fromShiftId = data.from_shiftid;
    this.shiftRequestForm.controls.appliedDate.setValue(data.applieddate);
    this.shiftRequestForm.controls.fromDate.setValue(data.fromdate);
    this.shiftRequestForm.controls.toDate.setValue(data.todate);
    this.shiftRequestForm.controls.fromShift.setValue(data.fromshift);
    this.shiftRequestForm.controls.toShift.setValue(data.to_shiftid);
    this.shiftRequestForm.controls.reason.setValue(data.reason);
  }
  view(data: any) {
    this.getActiveShiftIds();
    this.isShow = false,
      this.isNew = true,
      this.isView = true;
    this.shiftRequestForm.controls.appliedDate.setValue(data.applieddate);
    this.shiftRequestForm.controls.appliedDate.disable();
    this.shiftRequestForm.controls.fromDate.setValue(data.fromdate);
    this.shiftRequestForm.controls.fromDate.disable();
    this.shiftRequestForm.controls.toDate.setValue(data.todate);
    this.shiftRequestForm.controls.toDate.disable();
    this.shiftRequestForm.controls.fromShift.setValue(data.fromshift);
    this.shiftRequestForm.controls.fromShift.disable();
    this.shiftRequestForm.controls.toShift.setValue(data.to_shiftid);
    this.shiftRequestForm.controls.toShift.disable();
    this.shiftRequestForm.controls.reason.setValue(data.reason);
    this.shiftRequestForm.controls.reason.disable();
  }
  getShiftRequestList() {
    this.shiftDataList = [];
    let data = {
      "empid_value": this.employeeId,
      "rm_id_value": null,
    }
    this.attendanceService.getShiftChangeRequestList(data).subscribe((res) => {
      if (res.status) {
        this.shiftDataList = res.data;
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      } else {
        this.shiftDataList = [];
        this.dataSource = new MatTableDataSource(this.shiftDataList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }

  deleteRequestPopup(event: any) {
    let dialogRef = this.dialog.open(ComfirmationDialogComponent, {
      position: { top: `100px` },
      disableClose: true,
      data: { message: this.ATT85, YES: 'Yes', NO: 'No' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        this.deleteRequest(event.id)
      }
    });
  }

  deleteRequest(id: any) {
    this.spinner.show()
    let data = {
      "id_value": id,

    };
    this.attendanceService.deleteShiftRequest(data).subscribe((res: any) => {
      if (res.status) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.ATT80
        });
        this.spinner.hide();
        this.resetForm();
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.ATT86
        });
        this.spinner.hide();
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  resetForm() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Attendance/shift-change-request"]));
  }

  getMessagesList() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    this.adminService.getMessagesListApi(data).subscribe((res: any) => {
      if (res.status) {
        this.messagesDataList = res.data;
        this.messagesDataList.forEach((e: any) => {
          if (e.code == "ATT2") {
            this.requiredOption = e.message
          } else if (e.code == "ATT1") {
            this.requiredField = e.message
          } else if (e.code == "ATT12") {
            this.dataNotSave = e.message
          } else if (e.code == "ATT11") {
            this.dataSave = e.message
          } else if (e.code == "ATT77") {
            this.ATT77 = e.message
          } else if (e.code == "ATT78") {
            this.ATT78 = e.message
          } else if (e.code == "ATT79") {
            this.ATT79 = e.message
          } else if (e.code == "ATT80") {
            this.ATT80 = e.message
          } else if (e.code == "ATT81") {
            this.ATT81 = e.message
          } else if (e.code == "ATT82") {
            this.ATT82 = e.message
          } else if (e.code == "ATT83") {
            this.ATT83 = e.message
          } else if (e.code == "ATT84") {
            this.ATT84 = e.message
          } else if (e.code == "ATT85") {
            this.ATT85 = e.message
          } else if (e.code == "ATT86") {
            this.ATT86 = e.message
          }
        })
      }
      else {
        this.messagesDataList = [];
      }

    })
  }

  // 

  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);

    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  filterList(searchText: any) {
    this.filteredToShifts = this.activeShiftsList.filter((val: any) =>
      val.shiftname.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredToShifts.length <= 0) {
      this.searchControl.setValue('');
    }
  }
}

