<form fxLayout="column" [formGroup]="shiftReportForm" style="margin-left: 10px;">
    <div fxLayout="row" fxLayout.lt-lg="column">
        <div fxLayout="row" fxFlex="50" fxLayout.xs="column">

            <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                <mat-label>City-Branch</mat-label>
                <mat-select placeholder="" name="companylocation" formControlName="companylocation" required multiple
                    #locationSelect>
                    <button mat-raised-button class="mat-primary fill text-sm"
                        (click)="locationSelectAll(locationSelect, worklocationDetails, shiftReportForm.companylocation)"
                        *ngIf="!locationIshide">Select All
                    </button>
                    <button mat-raised-button class="mat-accent fill text-sm"
                        (click)="locationDeselectAll(locationSelect)" *ngIf="locationIshide">
                        Deselect All
                    </button>
                    <input matInput [formControl]="searchControl" type="text" placeholder="  Search" class="search-align">
                    <mat-option *ngFor="let res of filteredLocations" [value]="res" (click)="onchangeLocation(res)">
                        {{res.cityname}}-{{res.location}}
                    </mat-option>
                </mat-select>
                <mat-error class="con-error" *ngIf="shiftReportForm.controls.companylocation.errors?.required">
                    Please select an option
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="46" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                <mat-label>Manager</mat-label>
                <mat-select placeholder="" name="managers" formControlName="managers" required multiple #managerSelect>
                    <button mat-raised-button class="mat-primary fill text-sm"
                        (click)="managerSelectAll(managerSelect, managersDetails, shiftReportForm.managers)"
                        *ngIf="!mgrIshide">Select All
                    </button>
                    <button mat-raised-button class="mat-accent fill text-sm"
                        (click)="managerDeselectAll(managerSelect)" *ngIf="mgrIshide">
                        Deselect All
                    </button>
                    <input matInput [formControl]="searchControlforManager" type="text" placeholder="  Search"
                        class="search-align">
                    <mat-option *ngFor="let res of filteredManagerDetails" [value]="res" (click)="onchangeManager(res)">
                        {{res.manager_name}}
                    </mat-option>
                </mat-select>
                <mat-error class="con-error" *ngIf="shiftReportForm.controls.managers.errors?.required">
                    Please select an option
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxFlex="50" fxLayout.xs="column">
            <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                <mat-label>From Date</mat-label>
                <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate"
                    placeholder="From Date" formControlName="fromDate" (click)="fromDate.open()" tabindex="3" />
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                <mat-datepicker #fromDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                <mat-label>To Date</mat-label>
                <input matInput readonly [matDatepicker]="toDate" [min]="minDate" [max]="maxDate"
                    placeholder="From Date" formControlName="toDate" (click)="toDate.open()" tabindex="4" />
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
                <mat-datepicker #toDate></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="end">
            <span>
                <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabindex="3">
                    Search
                </button>
                <span class="line" style="color: #1898d5; font-size: 16px"></span>
                <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabindex="4">
                    <span>Clear</span>
                </button>
            </span>
        </div>
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="end" *ngIf="shiftDataList.length > 0">
            <div style="margin-right: 2%">
                <button mat-icon-button tabindex="5" (keyup.enter)="exportPDF()">
                    <a title="PDF">
                        <img (click)="exportPDF()" src="./assets/images/icons/pdf.png" width="20px" height="20px" /></a>
                </button>
            </div>
        </div>
    </div>

</form>


<div class="p-2" *ngIf="shiftDataList.length > 0">
    <div class="example-container" style="padding: 10px" #table>
        <table style="width: 100%" *ngIf="shiftDataList.length > 0">
            <thead>
                <tr>
                    <ng-container *ngFor="let key of getObjectKeys(headersList[0])">
                        <th>
                            <span class="header-cell">
                                {{ key }}
                            </span>
                        </th>

                    </ng-container>
                    <th class="header-cell">Details

                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of shiftDataList">
                    <tr style="width: auto">
                        <ng-container *ngFor="let key of getObjectKeys(item) ">
                            <td>
                                <span>
                                    {{ item[key] }}
                                </span>
                            </td>
                        </ng-container>
                        <td>
                            <button mat-icon-button (click)="view(item.Date)" title="View">
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

        <div style="text-align: center; padding-top: 20px" *ngIf="shiftDataList.length === 0">
            No data found.
        </div>
    </div>
</div>