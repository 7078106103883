<div fxLayout="column" class="first-col">

  <mat-card>
    <mat-card-header class="headerbox">
      <mat-card-title class="title"> Employee Payments Report </mat-card-title>
    </mat-card-header>
    <form [formGroup]="searchForm" class="tableP">
      <!-- <div fxLayout="row" fxLayout.lt-lg="column">

 
       </div> -->
      <div fxLayout="row">
        <mat-form-field fxFlex="30" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
          <mat-label>City-Branch </mat-label>
          <mat-select placeholder="" name="companylocation" formControlName="companylocation" required multiple
            #locationSelect>
            <button mat-raised-button class="mat-primary fill text-sm"
              (click)="locationSelectAll(locationSelect, worklocationDetails, searchForm.companylocation)"
              *ngIf="!locationIshide">Select All
            </button>
            <button mat-raised-button class="mat-accent fill text-sm" (click)="locationDeselectAll(locationSelect)"
              *ngIf="locationIshide">
              Deselect All
            </button>
            <input class="search-align" type="text" placeholder="  Search" [formControl]="searchControlForLocation"
              matInput>
            <mat-option *ngFor="let res of filteredLocations" [value]="res" (click)="onchangeLocation(res)">
              {{res.cityname}}-{{res.location}}
            </mat-option>
          </mat-select>
          <mat-error class="con-error" *ngIf="searchForm.controls.companylocation.errors?.required">
            Please select an option
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="30" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
          <mat-label>Manager</mat-label>
          <mat-select placeholder="" name="managers" formControlName="managers" required multiple #managerSelect>
            <button mat-raised-button class="mat-primary fill text-sm"
              (click)="managerSelectAll(managerSelect, managersDetails, searchForm.managers)" *ngIf="!mgrIshide">Select
              All
            </button>
            <button mat-raised-button class="mat-accent fill text-sm" (click)="managerDeselectAll(managerSelect)"
              *ngIf="mgrIshide">
              Deselect All
            </button>
            <input class="search-align" type="text" placeholder="  Search" [formControl]="searchControlforManager"
              matInput>
            <mat-option *ngFor="let res of filteredManagerList" [value]="res" (click)="onchangeManager(res)">
              {{res.manager_name}}
            </mat-option>
          </mat-select>
          <mat-error class="con-error" *ngIf="searchForm.controls.managers.errors?.required">
            Please select an option
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="30" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
          <mat-label>Month and Year</mat-label>
          <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate" placeholder=""
            (click)="fromDate.open()" formControlName="fromDate" />
          <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate">
          </mat-datepicker-toggle>
          <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)">
          </mat-datepicker>
        </mat-form-field>

      </div>
      <div fxLayout="row" fxLayoutAlign="center">
        <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabindex="3">
          Search
        </button>
        <span class="line" style="color: #1898d5; font-size: 16px"></span>
        <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabindex="4">
          <span>Clear</span>
        </button>
      </div>

    </form>
    <!-- <div style="margin-bottom: 1%;padding-right: 2%;" fxLayout="row xs-column" fxLayoutAlign="end  center" *ngIf="arrayList.length > 0">
        <div>
          <span class="exlprint"> | </span>
          <a title="PDF"> <img (click)="exportPDF()" src="./assets/images/icons/pdf.png"  width="20px" height="20px"></a>
  
          <span class="exlprint"> | </span>
          <a title="Excel"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
          </a>
        </div>
      </div > -->


    <!-- test -->
    <div class="tablePadding">
      <div class="mat-elevation-z1 example-container">

        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef> S. No.</th>
            <td mat-cell *matCellDef="let element; let i=index;">
              {{ (i+1)}} </td>
          </ng-container>

          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Location </th>
            <td mat-cell *matCellDef="let row"><span>{{row.location}} </span></td>
          </ng-container>

          <ng-container matColumnDef="empid">
            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp. ID </th>
            <td mat-cell *matCellDef="let row"><span>{{row.empcode}} </span></td>
          </ng-container>

          <ng-container matColumnDef="empname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Emp. Name </th>
            <td mat-cell *matCellDef="let row"><span>{{row.emp_name}} </span></td>
          </ng-container>

          <ng-container matColumnDef="totalgsalary">
            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Total Gross Salary</th>
            <td mat-cell *matCellDef="let row"><span>{{row.total_gross_salary}} </span></td>
          </ng-container>

          <ng-container matColumnDef="days">
            <th mat-header-cell *matHeaderCellDef>Days </th>
            <td mat-cell *matCellDef="let row"><span>{{row.paid_days}} </span></td>
          </ng-container>

          <ng-container matColumnDef="totalsalary">
            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Total Salary</th>
            <td mat-cell *matCellDef="let row"><span>{{row.total_salary}} </span></td>
          </ng-container>

          <ng-container matColumnDef="regularization">
            <th mat-header-cell *matHeaderCellDef>Regularization</th>
            <td mat-cell *matCellDef="let row" style="text-align: center;">

              <button mat-icon-button *ngIf="row.reg_required==1" title="Regularization is required.">
                <mat-icon style="color: red;">cancel</mat-icon>
              </button>

              <button mat-icon-button *ngIf="row.reg_required==0" matTooltip="Salary can be processed.">
                <mat-icon style="color: green;">check_circle_outline</mat-icon>
              </button>
              <span *ngIf="row.reg_required== null"></span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
          </tr>
        </table>

      </div>

    </div>
  </mat-card>
</div>