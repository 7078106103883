import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserData } from 'src/app/modules/attendance/models/EmployeeData';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { Moment } from 'moment';

import * as _moment from 'moment';

const moment = _moment;
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Router } from '@angular/router';
import { PayrollService } from 'src/app/modules/payroll/payroll.service';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DomSanitizer } from '@angular/platform-browser';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportsService } from '../../reports.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require('html-to-pdfmake');

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-common-reports',
  templateUrl: './common-reports.component.html',
  styleUrls: ['./common-reports.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CommonReportsComponent implements OnInit {
  dataSource: MatTableDataSource<UserData> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('table') table!: ElementRef;


  isReportUser: boolean = true;
  reportsList: any = UntypedFormGroup;
  reportsName: any = [
    { id: 'leave-report', name: 'Detailed Leave Report' },
    // { id: 'leave-summary-report', name: 'Leave Summary Report' },
    // { id: 'leave-payroll-report', name: 'Leave Payroll Report' },
    { id: 'leave-balance-report', name: 'Leave Balance Report' },
    { id: 'epf', name: 'EPF Report' },
    { id: 'esi', name: 'ESI Report' },
    // { id: 'employee-payment-report', name: 'Employee Payment Report' },
    { id: 'detailed-payroll-report', name: 'Detailed Payroll Report' },
    { id: 'professional-Tax-Report', name: 'Professional Tax Report' },
    { id: 'late-attendance-report', name: 'Late Attendance Report' },
    { id: 'detailed-attendance-report', name: 'Detailed Attendance Report' },
    { id: 'attendance-summary-report', name: 'Attendance Summary Report' },
    // { id: 'employee-reports', name: 'Employee Details' },
    { id: 'shiftwise-report', name: 'Shift Wise Attendance Report' },
    { id: 'checkin/out-summary-report', name: 'Punch Summary Report' },
    // { id: 'currendayAttReport', name: 'Currentday Attendance Report' }

  ];
  pageLoading = true;
  companyDBName: any = environment.dbName;
  userSession: any;
  pipe = new DatePipe('en-US');
  reportsToDisplay = '';
  searchControl = new FormControl('');
  filteredReports = this.reportsName;

  // -------------------------Declarations for Detailed leave report---------------

  detailedLeaveReports: any = UntypedFormGroup;
  employeeDetails: any = [];
  ishide: boolean = true;
  isCustom: boolean = false;
  minFromDate: any;
  leaveTypes: any = [];
  designations: any = [];
  arrayList: any = [];
  displayedColumns: string[] = [
    'sno',
    'employeeName',
    'employeeId',
    'leaveType',
    'designation',
    'appliedDate',
    'startDate',
    'toDate',
    'noOfDays',
    'status',
    'approvedBy',
  ];

  // Declarations for summary leave report----------

  leaveSummaryReport: any = UntypedFormGroup;
  calengerYearsdetails: any = [];
  adddate: any;
  summaryReportTableHeadings: any = [];
  summaryReports: any = [];
  datatwo: any = [];
  data: any = [];

  // Declarations for leave payroll report

  leavePayrollReport: any = UntypedFormGroup;
  max = new Date();
  minDate: any;
  dataSourceForPayrollReport: MatTableDataSource<UserData> = <any>[];
  payrollReportList: any = [];
  displayedColumnsForLeavePayrollReport: string[] = [
    'sno',
    'empid',
    'empname',
    'totalleavebalance',
    'lossofpay',
    'totalleaves',
  ];

  // Declarations for Leave Balance Report
  dataForLeaveBalanceReport: any = [];

  // Declarations for EPF report
  EpfReport: any = UntypedFormGroup;
  maxDateForEPF: any = new Date();
  minDateForEPF: any = new Date('2022-01-01');
  date = new UntypedFormControl(moment());
  messageflag: boolean = true;
  datadatas: any = [];
  message: any;
  year: any;

  months = [
    { id: 0, month: 'Jan' },
    { id: 1, month: 'Feb' },
    { id: 2, month: 'Mar' },
    { id: 3, month: 'Apr' },
    { id: 4, month: 'May' },
    { id: 5, month: 'Jun' },
    { id: 6, month: 'Jul' },
    { id: 7, month: 'Aug' },
    { id: 8, month: 'Sep' },
    { id: 9, month: 'Oct' },
    { id: 10, month: 'Nov' },
    { id: 11, month: 'Dec' },
  ];
  monthdata: any;
  dataSourceForEPF: MatTableDataSource<any> = <any>[];
  datadata: any;
  displayedColumnsforEPF: string[] = [
    'sno',
    'uan',
    'empname',
    'gross',
    'epf',
    'eps',
    'edil',
    'epsvalue',
    'ee',
    'er',
    'ncp',
    'refunds',
  ];

  // --Declarations for ESI report------
  ESIReport: any = UntypedFormGroup;
  minDateForESI: any = new Date('2022-01-01');
  maxDateForESI = new Date();
  dateESI = new UntypedFormControl(moment());
  setMonthAndYearESI(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.dateESI.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.dateESI.setValue(ctrlValue);
    datepicker.close();
  }
  stateslistESI: any = [];
  messageflagESI: boolean = true;
  monthdataESI: any;
  selectedStateIdESI: any;
  dataSourceESI: MatTableDataSource<any> = <any>[];
  displayedColumnsForESI: string[] = [
    'ipnumber',
    'ipname',
    'nofdays',
    'totawages',
    'ipcontribution',
    'reasonforzeroworkingdays',
    'lastworkingdays',
  ];
  isTableView: boolean = false;
  constructor(
    public formBuilder: UntypedFormBuilder,
    public spinner: NgxSpinnerService,
    public router: Router,
    private payrollService: PayrollService,
    private LM: LeavesService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private reportsService: ReportsService
  ) {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
  }

  ngOnInit(): void {
    this.reportsList = this.formBuilder.group({
      reportsName: [''],
    });
    this.getReportsuserLocationMappingStatus();
    new Date().getFullYear();
    new Date().getMonth();
    if (new Date().getMonth() < 4) {
      this.adddate = Number(new Date().getFullYear()) - 1;
    } else {
      this.adddate = new Date().getFullYear();
    }

    this.leaveSummaryReport = this.formBuilder.group({
      designation: ['All'],
      employeeId: ['All'],
      calenderYear: [this.adddate],
    });

    this.searchControl.valueChanges.subscribe((searchText: any) => {
      this.filterReports(searchText);
    })

    // ---------- Leave Payroll Report -----------

    this.leavePayrollReport = this.formBuilder.group({
      fromDate: ['', Validators.required],
      employeeId: ['All'],
      toDate: ['', Validators.required],
    });

    this.leavePayrollReport
      .get('fromDate')
      ?.valueChanges.subscribe((selectedValue: any) => {
        this.minDate = selectedValue;
      });

    // ------------EPF Report----------
    this.EpfReport = this.formBuilder.group({
      fromDateForEPF: [new Date()],
    });

    // ----------ESI Report-----------

    this.ESIReport = this.formBuilder.group({
      fromDateForESI: [new Date()],
      state: [''],
    });

    this.ESIReport.get('dateESI')?.valueChanges.subscribe(
      (selectedValue: any) => {
        this.getEsiValuesForChallanForReportsuser();
      }
    );

    this.ESIReport.get('state')?.valueChanges.subscribe(
      (selectedValue: any) => {
        this.selectedStateIdESI = selectedValue;
        this.getEsiValuesForChallanForReportsuser();
      }
    );
    //  getESIValuesForChallan
    // this.getLeaveTypes(); /**for Detailed leave report */
    this.getEmployeesForReportingManager(); /**for leave detailed and summary report */
    this.getDesignation(); /**for leave detailed and summary report */
    this.calengerYears(); /**for leave summary report */
    this.SearchLeaveSummaryReportForm(); /**for leave summary report */
    this.getLeaveBalanceReportForReportsuser(); /**for leave balance report */
    // this.getEpfValuesForChallanForReportuser(); /**for EPF report */
    this.getStatesList(); /**for ESI report */
  }

  selectReport() {
    this.reportsToDisplay = this.reportsList.controls.reportsName.value;
  }

  cancel() {
    this.navigationToCommonReports();
  }

  navigationToCommonReports() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Attendance/commonReports']));
  }

  calengerYears() {
    this.LM.getYearsForReport().subscribe((result) => {
      if (result && result.status) {
        this.calengerYearsdetails = result.data;
      }
    });
  }
  SearchLeaveSummaryReportForm() {
    let data = {
      employee_id: this.leaveSummaryReport.controls.employeeId.value,
      designation_id: this.leaveSummaryReport.controls.designation.value,
      department_id: 'All',
      display_year: this.leaveSummaryReport.controls.calenderYear.value,
    };
    this.LM.getSummaryReportForReportsuser(data).subscribe((result: any) => {
      this.spinner.hide();
      this.summaryReportTableHeadings = [];
      this.summaryReports = [];
      this.datatwo = [];
      this.data = [];
      if (result && result.status) {
        for (let i = 0; i < result.data.length; i++) {
          this.data = Object.keys(result.data[i]);
          this.datatwo = Object.values(result.data[i]);
          var info = {
            column1: this.datatwo[0],
            column2: this.datatwo[1],
            column3: this.datatwo[2],
            column4: this.datatwo[3],
            column5: this.datatwo[4],
            column6: this.datatwo[5],
            column7: this.datatwo[6],
            column8: this.datatwo[7],
            column9: this.datatwo[8],
            column10: this.datatwo[9],
            column11: this.datatwo[10],
            column12: this.datatwo[11],
            column13: this.datatwo[12],
            column14: this.datatwo[13],
          };
          this.summaryReports.push(info);
        }
        var obj = {
          column1: this.data[0].trim(),
          column2: this.data[1],
          column3: this.data[2],
          column4: this.data[3],
          column5: this.data[4],
          column6: this.data[5],
          column7: this.data[6],
          column8: this.data[7],
          column9: this.data[8],
          column10: this.data[9],
          column11: this.data[10],
          column12: this.data[11],
          column13: this.data[12],
          column14: this.data[13],
        };
        this.summaryReportTableHeadings.push(obj);
      }
    });
  }
  clearLeaveSummaryForm() {
    this.leaveSummaryReport.controls.designation.setValue('All');
    this.leaveSummaryReport.controls.employeeId.setValue('All');
    this.leaveSummaryReport.controls.calenderYear.setValue(this.adddate);
  }
  public exportPDFleaveSummaryReport(): void {
    const pdfTable = this.table.nativeElement;
    let year = this.leaveSummaryReport.controls.calenderYear.value;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake
      .createPdf({
        info: {
          title: year + '-' + 'Leave Summary Report',
          author: 'Sreeb tech',
          subject: 'Theme',
          keywords: 'Report',
        },
        footer: function (currentPage: any, pageCount: any) {
          return {
            margin: 10,
            columns: [
              {
                fontSize: 9,
                text: [
                  {
                    text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  },
                ],
                alignment: 'center',
              },
            ],
          };
        },
        content: [
          {
            text: year + '-' + 'Leave Summary Report\n\n',
            style: 'header',
            alignment: 'center',
            fontSize: 14,
          },
          html,
        ],
        pageOrientation: 'landscape', //'portrait'
        pageSize: 'A4',
      })
      .download(year + '-' + 'Leave Summary Report.pdf');
  }

  exportAsXLSXleaveSummaryReport() {
    let year = this.leaveSummaryReport.controls.calenderYear.value;
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('tableBorder')
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Leave_Summary_Report');

    /* save to file */
    XLSX.writeFile(wb, year + '-' + 'Leave_Summary_Report.xlsx');
  }

  getEmployeesForReportingManager() {
    let obj = {
      remployee_id: this.userSession.id,
    };
    this.reportsService
      .getReportsuserEmployeesList(obj)
      .subscribe((res: any) => {

        if (res.status) {
          this.employeeDetails = [];
          this.employeeDetails = res.data;
          // this.leaveSummaryReport.controls.employeeId.setValue('0');
        }
      });

  }

  getDesignation() {
    let obj = {
      tableName: 'designationsmaster',
      status: 1,
      pageNumber: 1,
      pageSize: 1000,
      databaseName: this.companyDBName,
    };
    this.LM.getMastertable(obj).subscribe((result) => {
      if (result && result.status) {
        this.designations = result.data;
      }
    });
  }

  // -----Leave Payroll Report-----------

  SearchLeavePayrollReportForm() {
    if (this.leavePayrollReport.valid) {
      let data = {
        empid:
          this.leavePayrollReport.controls.employeeId.value == 'All'
            ? null
            : this.leavePayrollReport.controls.employeeId.value,
        fromDate: this.pipe.transform(
          this.leavePayrollReport.controls.fromDate.value,
          'yyyy-MM-dd'
        ),
        toDate: this.pipe.transform(
          this.leavePayrollReport.controls.toDate.value,
          'yyyy-MM-dd'
        ),
      };
      this.spinner.show();
      this.LM.getReportForPayrollProcessing(data).subscribe((res: any) => {
        this.spinner.hide();
        this.payrollReportList = [];
        if (res.status && res.data.length > 0) {
          this.payrollReportList = res.data;
          this.dataSourceForPayrollReport = new MatTableDataSource(
            this.payrollReportList
          );
          this.dataSourceForPayrollReport.paginator = this.paginator;
          this.dataSourceForPayrollReport.paginator.pageSize = 5;
          this.dataSourceForPayrollReport.sort = this.sort;
          this.pageLoading = false;
        } else {
          this.payrollReportList = [];
          this.dataSourceForPayrollReport = new MatTableDataSource(
            this.payrollReportList
          );
          this.dataSourceForPayrollReport.paginator = this.paginator;
          this.dataSourceForPayrollReport.paginator.pageSize = 5;
          this.dataSourceForPayrollReport.sort = this.sort;
        }
      });
    }
  }

  clearLeavePayrollReportForm() {
    this.leavePayrollReport.controls.fromDate.setValue('');
    this.leavePayrollReport.controls.employeeId.setValue('All');
    this.leavePayrollReport.controls.toDate.setValue('');
  }

  public exportPDFleavePayrollReport(): void {
    let fromDate = this.pipe.transform(
      this.leavePayrollReport.controls.fromDate.value,
      'dd-MM-yyyy'
    );
    let toDate = this.pipe.transform(
      this.leavePayrollReport.controls.toDate.value,
      'dd-MM-yyyy'
    );
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake
      .createPdf({
        info: {
          title: fromDate + '-' + toDate + '-' + 'Payroll Report',
          author: 'Sreeb tech',
          subject: 'Theme',
          keywords: 'Report',
        },
        footer: function (currentPage: any, pageCount: any) {
          return {
            margin: 10,
            columns: [
              {
                fontSize: 9,
                text: [
                  {
                    text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  },
                ],
                alignment: 'center',
              },
            ],
          };
        },
        content: [
          {
            text: 'Payroll Report\n\n',
            style: 'header',
            alignment: 'center',
            fontSize: 14,
          },
          html,
        ],
        pageOrientation: 'landscape', //'portrait'
        pageSize: 'A4',
      })
      .download(fromDate + '-' + toDate + '-' + 'Payroll Report.pdf');
  }

  exportAsXLSXleavePayrollReport() {
    let fromDate = this.pipe.transform(
      this.leavePayrollReport.controls.fromDate.value,
      'dd-MM-yyyy'
    );
    let toDate = this.pipe.transform(
      this.leavePayrollReport.controls.toDate.value,
      'dd-MM-yyyy'
    );

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('table')
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Detailed_Report');

    /* save to file */
    XLSX.writeFile(wb, fromDate + '-' + toDate + '-' + 'Payroll_Report.xlsx');
  }

  // ------Leave Balance Report-----

  getHeaders() {
    let headers: string[] = [];
    if (this.dataForLeaveBalanceReport) {
      this.dataForLeaveBalanceReport.forEach((value: any) => {
        Object.keys(value).forEach((key) => {
          if (!headers.find((header) => header == key)) {
            headers.push(key);
          }
        });
      });
    }
    return headers;
  }

  getLeaveBalanceReportForReportsuser() {
    let data = {
      id: this.userSession.id,
    };
    this.payrollService
      .getLeaveBalanceReportForReportsuser(data)
      .subscribe((info: any) => {
        this.dataForLeaveBalanceReport = info.data;
      });
  }

  public exportPDFleaveBalanceReport(): void {
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake
      .createPdf({
        info: {
          title: 'Leave Balance Report',
          author: 'Sreeb tech',
          subject: 'Theme',
          keywords: 'Report',
        },
        footer: function (currentPage: any, pageCount: any) {
          return {
            margin: 10,
            columns: [
              {
                fontSize: 9,
                text: [
                  {
                    text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  },
                ],
                alignment: 'center',
              },
            ],
          };
        },
        content: [
          {
            text: 'Leave Balance Report\n\n',
            style: 'header',
            alignment: 'center',
            fontSize: 14,
          },

          html,
        ],
        pageOrientation: 'landscape', //'portrait'
        pageSize: 'A4',
      })
      .download('Leave Balance Report.pdf');
  }

  exportAsXLSXleaveBalanceReport() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('table')
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Leave_Balance_Report');

    /* save to file */
    XLSX.writeFile(wb, 'Leave_Balance_Report.xlsx');
  }

  getStatesList() {
    this.payrollService.getCompanyEsiValues().subscribe((result: any) => {
      if (result.status && result.data.length > 0) {
        this.stateslistESI = result.data[0];
      } else {
        this.stateslistESI = [];
      }
    });
  }

  public exportPDFforESI(): void {
    if (this.messageflagESI) {
      const pdfTable = this.table.nativeElement;
      var html = htmlToPdfmake(pdfTable.innerHTML);
      pdfMake
        .createPdf({
          info: {
            title: 'ESI Report',
            author: 'Sreeb tech',
            subject: 'Theme',
            keywords: 'Report',
          },
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  fontSize: 9,
                  text: [
                    {
                      text:
                        'Page ' + currentPage.toString() + ' of ' + pageCount,
                    },
                  ],
                  alignment: 'center',
                },
              ],
            };
          },
          content: [
            {
              text: 'Payroll Report\n\n',
              style: 'header',
              alignment: 'center',
              fontSize: 14,
            },

            html,
          ],
          pageOrientation: 'landscape', //'portrait'
        })
        .download('ESI Report.pdf');
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: this.message,
      });
    }
  }

  exportAsXLSXforESI() {
    if (this.messageflagESI) {
      this.year = this.ESIReport.controls.fromDateForESI.value.getFullYear();
      for (let i = 0; i < this.months.length; i++) {
        if (
          this.ESIReport.controls.fromDateForESI.value.getMonth() ==
          this.months[i].id
        ) {
          this.monthdataESI = this.months[i].month;
          break;
        }
      }
      var ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
        document.getElementById('table')
      );
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'ESI_report_for_financeteam_');
      /* save to file */
      XLSX.writeFile(
        wb,
        'ESI_report_for_financeteam_' +
        this.monthdataESI +
        '_' +
        this.year +
        '.xlsx'
      );
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: this.message,
      });
    }
  }
  getEsiValuesForChallanForReportsuser() {
    let data = {
      year_value: this.dateESI.value._d.getFullYear(),
      month_value: this.dateESI.value._d.getMonth() + 1,
      stateid_value: Number(this.selectedStateIdESI),
      rempid: this.userSession.id,
    };
    this.dataSourceESI = new MatTableDataSource();
    this.payrollService
      .getEsiValuesForChallanForReportsuser(data)
      .subscribe((result: any) => {
        if (result.status) {
          this.dataSourceESI = new MatTableDataSource(result.data);
          this.dataSourceESI.paginator = this.paginator;
          this.dataSourceESI.sort = this.sort;
          this.pageLoading = false;
          // this.validateEsiChallanDownload();
        }
      });
  }

  getPageSizesESI(): number[] {
    var customPageSizeArray = [];
    if (this.dataSourceESI.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSourceESI.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSourceESI.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSourceESI.data.length);
    return customPageSizeArray;
  }

  getReportsuserLocationMappingStatus() {
    this.reportsService.getReportsuserLocationMappingStatus(this.userSession.id).subscribe((res: any) => {
      if (res.status) {
        this.isTableView = res.data[0].mapping_status === 1 ? true : false;
        if (this.isTableView == false) {
          this.reportsList.controls.reportsName.disable();
        } else {
          this.reportsList.controls.reportsName.enable();
        }
      }
    });

  }

  filterReports(searchText: any) {
    this.filteredReports = this.reportsName.filter((val: any) =>
      val.name.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredReports.length <= 0) {
      this.searchControl.setValue('');
    }
  }

} /**end */
