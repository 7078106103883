import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReportsService } from '../../reports.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { DatePipe } from '@angular/common';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import * as XLSX from 'xlsx';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import { CheckinOutSummaryReportDialogComponent } from '../checkin-out-summary-report-dialog/checkin-out-summary-report-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';

const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-checkin-out-summary-report',
  templateUrl: './checkin-out-summary-report.component.html',
  styleUrls: ['./checkin-out-summary-report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CheckinOutSummaryReportComponent implements OnInit {
  @ViewChild('table') table!: ElementRef;

  searchForm: any = UntypedFormGroup;
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  selectedLocations: any = [];
  managersDetails: any[] = [];
  companyName: any;
  filteredManagers: any = [];
  worklocationDetails: any[] = [];
  searchControl = new FormControl('');

  filteredLocations: any = [];
  mgrIshide: boolean = false;
  mgrIschecked: boolean = false;
  selectedManagers: any = [];
  employeelist: any;
  filteredEmployees: any = [];
  cdate = new UntypedFormControl(moment());
  List: any = [];
  minDate = new Date('2020/01/01');
  maxDate = new Date();
  userSession: any;
  empId: any;
  isLoading = false;
  dataSource: MatTableDataSource<any> = <any>[];
  headersList: any = [];
  dateValue: any = [];

  constructor(
    public reportsService: ReportsService,
    public datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.companyName = sessionStorage.getItem('companyName');

    this.searchForm = this.formBuilder.group({
      fromDate: [new Date()],
      toDate: [new Date()],
      employees: ['0'],
    });
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.searchControl.valueChanges.subscribe((searchText) => {
      this.filterEmployees(searchText);
    });

    this.getEmployeelist();
    this.Searchform();
  }

  filterEmployees(searchText: any) {
    this.filteredEmployees = this.employeelist.filter(
      (val: any) =>
        val.empcode.toLowerCase().includes(searchText.toLowerCase()) ||
        val.empname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmployees.length <= 0) {
      this.searchControl.setValue('');
    }
  }

  getEmployeelist() {
    let obj = {
      rm_id: this.userSession.id,
    };
    this.reportsService
      .getTotalEmployeslistByManagerId(obj)
      .subscribe((res: any) => {
        if (res.status) {
          this.employeelist = [];
          this.employeelist = res.data;
          this.filteredEmployees = this.employeelist;
          this.searchForm.controls.employees.setValue('0');
        }
      });
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.cdate.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.searchForm.controls.fromDate.setValue(ctrlValue);
    datepicker.close();
  }

  public exportPDF(): void {
    let date = this.datePipe.transform(
      this.searchForm.controls.fromDate.value,
      'MM-YYYY'
    );
    const pdfTable = this.table.nativeElement;

    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake
      .createPdf({
        info: {
          title: date + '-' + 'Attendance Monthly Detailed Report',
          author: 'Sreeb tech',
          subject: 'Theme',
          keywords: 'Report',
        },
        footer: function (currentPage, pageCount) {
          return {
            margin: 10,
            columns: [
              {
                fontSize: 9,
                text: [
                  {
                    text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  },
                ],
                alignment: 'center',
              },
            ],
          };
        },
        content: [
          {
            text: date + ' - ' + 'Attendance Monthly Detailed Report \n\n',
            style: 'header',
            alignment: 'center',
            fontSize: 14,
          },
          html,
        ],
        styles: {
          'html-table': {
            background: 'white', // it will add a yellow background to all <STRONG> elements
          },
        },
        pageOrientation: 'landscape',
        pageSize: 'A3',
        //'portrait'
      })
      .download('Attendance Monthly Detailed Report.pdf');
  }

  exportAsXLSX() {
    let date = this.datePipe.transform(
      this.searchForm.controls.fromDate.value,
      'MM-YYYY'
    );

    const displayedColumns = Object.keys(this.dataSource.data[0]);
    const headercolumn = [
      'S. No.',
      'Emp. Code',
      'Emp. Name',
      ...this.headersList[0].map((date: any, index: any) => {
        const dayNumber = index + 1; // Index + 1 for 1-based numbering
        const dayWeek = this.headersList[1][index]?.slice(0, 2) || ''; // Get first two letters of weekday or default to empty string
        return `${dayNumber}${dayWeek}`; // Format as "1Th", "2Fr", etc.
      }),
    ];

    // Example data row; in real usage, map your actual data source here

    const data = this.dataSource.data.map((row) =>
      displayedColumns.map((col: any) => {
        let value = row[col];
        return Array.isArray(value) ? value.join(', ') : value;
      })
    );

    // const header = displayedColumns.map((col: any) => col.charAt(0).toUpperCase() + col.slice(1));
    const excelData = [headercolumn, ...data];

    // Create a worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelData);

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Punch Summary Report');

    // Save the file
    XLSX.writeFile(wb, date + '-' + 'punch_summary_report.xlsx');
  }

  resetform() {
    this.searchForm.controls.employees.setValue('0');
    this.searchForm.controls.fromDate.setValue(new Date());
    this.Searchform();
  }
  getColor(i: string): String {
    let color = '';
    if (i == 'P') {
      return (color = 'green');
    } else if (i == 'H') {
      return (color = '#800000');
    } else if (i == 'W') {
      return (color = 'blue');
    } else if (i == 'L') {
      return (color = 'orange');
    } else if (i == 'HD') {
      return (color = '#ce06e4');
    } else if (i == 'WP') {
      return (color = '#06c3e4');
    } else if (i == 'HP') {
      return (color = '#06e471');
    } else if (i == 'A') {
      return (color = 'red');
    } else {
      return (color = 'black');
    }
  }

  Searchform() {
    if (this.searchForm.valid) {
      this.dateValue = this.datePipe.transform(
        this.searchForm.controls.fromDate.value,
        'y-MM-dd'
      );
      let data = {};
      let employeeid = this.searchForm.controls.employees.value;
      if (employeeid === '0') {
        employeeid = null;
        data = {
          manager_id: this.userSession.id,
          employee_id: employeeid,
          calendar_date: this.datePipe.transform(
            this.searchForm.controls.fromDate.value,
            'y-MM-dd'
          ),
        };
      } else {
        data = {
          manager_id: null,
          employee_id: parseInt(employeeid),
          calendar_date: this.datePipe.transform(
            this.searchForm.controls.fromDate.value,
            'y-MM-dd'
          ),
        };
      }
      this.spinner.show();
      this.isLoading = true;
      this.reportsService.getAttendanceDetailedMonthlyReport(data).subscribe(
        (res: any) => {
          this.headersList = [];
          this.List = [];
          if (res.status) {
            let i = 0;
            res.data.forEach((e: any) => {
              if (i < 2) {
                let header = JSON.parse(e.result);
                this.headersList.push(header);
              } else {
                let header = JSON.parse(e.result);
                this.List.push(header);
              }
              i++;
            });
          }

          this.isLoading = false;
          this.dataSource = new MatTableDataSource(this.List);
          this.spinner.hide();
        },
        (error) => {
          this.isLoading = false;
          error.error.text;
          this.spinner.hide();
        }
      );
    }
  }

  // this function is check if the result data is array or string
  isArray(item: any): boolean {
    return Array.isArray(item);
  }

  openDialog(item: any) {
    const dialogRef = this.dialog.open(CheckinOutSummaryReportDialogComponent, {
      width: '1000px',
      position: { top: `70px` },
      data: {
        employeeId: item[1],
        empname: item[2],
        calenderDate: this.dateValue,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
}
