import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import * as _moment from 'moment';
import { ReportsService } from '../../reports.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSelect } from '@angular/material/select';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as XLSX from 'xlsx';
import { CheckinOutSummaryReportDialogComponent } from '../checkin-out-summary-report-dialog/checkin-out-summary-report-dialog.component';
import { Router } from '@angular/router';

const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-admin-checkin-out-summary-report',
  templateUrl: './admin-checkin-out-summary-report.component.html',
  styleUrls: ['./admin-checkin-out-summary-report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdminCheckinOutSummaryReportComponent implements OnInit {
  @ViewChild('table') table!: ElementRef;

  searchForm: any = UntypedFormGroup;
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  selectedLocations: any = [];
  managersDetails: any[] = [];
  companyName: any;
  filteredManagers: any = [];
  worklocationDetails: any[] = [];
  searchControl = new FormControl('');
  searchControlforLoc = new FormControl('');
  searchControlforRM = new FormControl('');
  filteredLocations: any = [];
  mgrIshide: boolean = false;
  mgrIschecked: boolean = false;
  selectedManagers: any = [];
  employeelist: any;
  filteredEmployees: any = [];
  cdate = new UntypedFormControl(moment());
  List: any = [];
  minDate = new Date('2020/01/01');
  maxDate = new Date();
  userSession: any;
  empId: any;
  isLoading = false;
  dataSource: MatTableDataSource<any> = <any>[];
  headersList: any = [];
  dateValue: any = [];

  constructor(
    public reportsService: ReportsService,
    private companyService: CompanySettingService,
    public datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.companyName = sessionStorage.getItem('companyName');

    this.searchForm = this.formBuilder.group({
      companylocation: ['', Validators.required],
      managers: ['', Validators.required],
      fromDate: [new Date()],
      toDate: [new Date()],
      employees: ['0'],
    });
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.getWorkLocation();
    this.Searchform();
    this.searchControl.valueChanges.subscribe((searchText) => {
      this.filterEmployees(searchText);
    });
    this.searchControlforRM.valueChanges.subscribe((searchText) => {
      this.filterManager(searchText);
    });
    this.searchControlforLoc.valueChanges.subscribe((searchText) => {
      this.filterLocations(searchText);
    });
  }
  filterEmployees(searchText: any) {
    this.filteredEmployees = this.employeelist.filter((val: any) =>
      val.empname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmployees.length <= 0) {
      this.searchControl.setValue('');
    }
  }
  filterManager(searchText: any) {
    this.filteredManagers = this.managersDetails.filter((val: any) =>
      val.manager_name.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredManagers.length <= 0) {
      this.searchControlforRM.setValue('');
    }
  }
  filterLocations(searchText: any) {
    this.filteredLocations = this.worklocationDetails.filter(
      (val: any) =>
        val.cityname.toLowerCase().includes(searchText.toLowerCase()) ||
        val.location.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredLocations.length <= 0) {
      this.searchControlforLoc.setValue('');
    }
  }
  locationSelectAll(select: MatSelect, values: any, array: any) {
    this.locationIshide = true;
    this.locationIschecked = true;
    select.value = values;
    array = values;
    this.searchForm.controls.companylocation.setValue(array);
    let locations = this.searchForm.controls.companylocation.value;
    locations.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });

    this.getManagersList();
  }
  getManagersList() {
    this.managersDetails = [];
    let data = {
      location_id: JSON.stringify(this.selectedLocations),
      companyName: this.companyName,
    };
    this.reportsService
      .getManagersListByLocation(data)
      .subscribe((res: any) => {
        if (res.status && res.data.length > 0) {
          this.managersDetails = res.data;
          this.filteredManagers = this.managersDetails;
        }
      });
  }
  locationDeselectAll(select: MatSelect) {
    this.locationIshide = false;
    this.locationIschecked = false;
    this.selectedLocations = [];
    select.value = [];
    this.searchForm.controls.companylocation.setValue('');
    this.filteredManagers = [];
    this.filteredEmployees = [];
  }
  onchangeLocation(value: any) {
    this.selectedLocations = [];
    let location = this.searchForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }
  managerSelectAll(select: MatSelect, values: any, array: any) {
    this.mgrIshide = true;
    this.mgrIschecked = true;
    select.value = values;
    array = values;
    this.searchForm.controls.managers.setValue(array);
    let managerdata = this.searchForm.controls.managers.value;
    managerdata.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
    this.getEmployeelist();
  }

  managerDeselectAll(select: MatSelect) {
    this.mgrIshide = false;
    this.mgrIschecked = false;
    this.selectedManagers = [];
    select.value = [];
    this.searchForm.controls.managers.setValue('');
    this.selectedManagers = [];

    this.filteredEmployees = [];
  }

  getWorkLocation() {
    this.managersDetails = [];
    this.companyService
      .getactiveWorkLocation({ id: null, companyName: this.companyName })
      .subscribe((result) => {
        this.worklocationDetails = result.data;
        this.filteredLocations = this.worklocationDetails;
      });
  }
  getEmployeelist() {
    let obj = {
      rm_id: JSON.stringify(this.selectedManagers),
      location_id: JSON.stringify(this.selectedLocations),
    };
    this.reportsService
      .getLocationWiseEmployeesForManagers(obj)
      .subscribe((res: any) => {
        if (res.status) {
          this.employeelist = [];
          this.employeelist = res.data;
          this.filteredEmployees = this.employeelist;
          this.searchForm.controls.employees.setValue('0');
        }
      });
  }

  onchangeManager(value: any) {
    this.selectedManagers = [];
    let location = this.searchForm.controls.managers.value;
    location.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });

    this.getEmployeelist();
  }

  setMonthAndYear(
    normalizedMonthAndYear: _moment.Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.cdate.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.searchForm.controls.fromDate.setValue(ctrlValue);
    datepicker.close();
  }


  exportAsXLSX() {
    let date = this.datePipe.transform(
      this.searchForm.controls.fromDate.value,
      'MM-YYYY'
    );

    const displayedColumns = Object.keys(this.dataSource.data[0]);
    const headercolumn = [
      'S. No.',
      'Emp. Code',
      'Emp. Name',
      ...this.headersList[0].map((date: any, index: any) => {
        const dayNumber = index + 1; // Index + 1 for 1-based numbering
        const dayWeek = this.headersList[1][index]?.slice(0, 2) || ''; // Get first two letters of weekday or default to empty string
        return `${dayNumber}${dayWeek}`; // Format as "1Th", "2Fr", etc.
      }),
    ];

    // Example data row; in real usage, map your actual data source here

    const data = this.dataSource.data.map((row) =>
      displayedColumns.map((col: any) => {
        let value = row[col];
        return Array.isArray(value) ? value.join(', ') : value;
      })
    );

    // const header = displayedColumns.map((col: any) => col.charAt(0).toUpperCase() + col.slice(1));
    const excelData = [headercolumn, ...data];

    // Create a worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelData);

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Punch Summary Report');

    // Save the file
    XLSX.writeFile(wb, date + '-' + 'punch_summary_report.xlsx');
  }

  resetform() {
    this.router.navigateByUrl('/', { skipLocationChange: true })
    .then(() => this.router.navigate(['/Attendance/adminCheckInOutSummaryReport']));
  }
  getColor(i: string): String {
    let color = '';
    if (i == 'P') {
      return (color = 'green');
    } else if (i == 'H') {
      return (color = '#800000');
    } else if (i == 'W') {
      return (color = 'blue');
    } else if (i == 'L') {
      return (color = 'orange');
    } else if (i == 'HD') {
      return (color = '#ce06e4');
    } else if (i == 'WP') {
      return (color = '#06c3e4');
    } else if (i == 'HP') {
      return (color = '#06e471');
    } else if (i == 'A') {
      return (color = 'red');
    } else {
      return (color = 'black');
    }
  }

  Searchform() {
    if (this.searchForm.valid) {
      this.dateValue = this.datePipe.transform(
        this.searchForm.controls.fromDate.value,
        'y-MM-dd'
      );
      let empid = this.searchForm.controls.employees.value;
      let data = {};
      if (empid === '0') {
        data = {
          manager_employee_id: JSON.stringify(this.selectedManagers),
          location_id: JSON.stringify(this.selectedLocations),
          employee_id: empid,
          calendar_date: this.datePipe.transform(
            this.searchForm.controls.fromDate.value,
            'y-MM-dd'
          ),
        };
      } else {
        data = {
          manager_employee_id: null,
          location_id: JSON.stringify(this.selectedLocations),
          employee_id: empid,
          calendar_date: this.datePipe.transform(
            this.searchForm.controls.fromDate.value,
            'y-MM-dd'
          ),
        };
      }
      this.spinner.show();
      this.isLoading = true;
      this.reportsService
        .getLocationWiseDetailedAttendanceMonthlyReport(data)
        .subscribe(
          (res: any) => {
            this.headersList = [];
            this.List = [];
            if (res.status) {
              let i = 0;
              res.data.forEach((e: any) => {
                if (i < 2) {
                  let header = JSON.parse(e.result);
                  this.headersList.push(header);
                } else {
                  let header = JSON.parse(e.result);
                  this.List.push(header);
                }
                i++;
              });
            }

            this.isLoading = false;
            this.dataSource = new MatTableDataSource(this.List);
            this.spinner.hide();
          },
          (error) => {
            this.isLoading = false;
            error.error.text;
            this.spinner.hide();
          }
        );
    }
  }

  // this function is check if the result data is array or string
  isArray(item: any): boolean {
    return Array.isArray(item);
  }

  openDialog(item: any) {
    const dialogRef = this.dialog.open(CheckinOutSummaryReportDialogComponent, {
      width: '1100px',
      position: { top: `70px` },
      data: {
        employeeId: item[1],
        empname: item[2],
        calenderDate: this.dateValue,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
