<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div fxLayout="row" fxLayoutAlign="start">
    <h1 mat-dialog-title>Current Day Punch Details</h1>
</div>
<mat-divider></mat-divider>
<div fxLayout="row" style="align-items: stretch;">
    <div fxLayout="column" style="margin-top: 20px;">
        <div fxFlex="48" fxLayout="column" style="max-width: 600px;">
            <div fxLayout="row" style="margin-bottom: 5px;">
                <p style="flex: 1;"><strong>Date :</strong></p>
                <span style="flex: 2;">{{attendanceDate | date:'dd-MM-yyyy'}}</span>
            </div>
            <div fxLayout="row" style="margin-bottom: 5px;">
                <p style="flex: 1;"><strong>Employee ID :</strong></p>
                <span style="flex: 2;">{{empId}}</span>
            </div>
            <div fxLayout="row" style="margin-bottom: 5px;">
                <p style="flex: 1;"><strong>Name :</strong></p>
                <span style="flex: 2;">{{empName}}</span>
            </div>
            <!-- <div fxLayout="row" style="margin-bottom: 5px;">
                <p style="flex: 1;"><strong>Total Work Hours :</strong></p>
                <span style="flex: 2;">{{empTotalHrs}}</span>
            </div>

            <div fxLayout="row" style="margin-bottom: 5px;">
                <p style="flex: 1;"><strong>Break Time :</strong></p>
                <span style="flex: 2;">{{empBreaktime}}</span>
            </div>
            <div fxLayout="row" style="margin-bottom: 5px;">
                <p style="flex: 1;"><strong>Productive Hours :</strong></p>
                <span style="flex: 2;">{{empProductHrs}}</span>
            </div> -->


        </div>
        <!-- <div fxLayout="row"> -->
        <div class="mat-elevation-z1 example-container">
            <table mat-table [dataSource]="dataSource" *ngIf="List.length > 0">
                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef>S. No.</th>
                    <td mat-cell *matCellDef="let row; let i = index;">{{i+1}}</td>

                </ng-container>
                <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef>Punch Time</th>
                    <td mat-cell *matCellDef="let row;">{{row.punchtime | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                </ng-container>

                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef>Category</th>
                    <td mat-cell *matCellDef="let row"> {{row.category}} </td>
                </ng-container>

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef>Location</th>
                    <td mat-cell *matCellDef="let row"> {{row.location}} </td>
                </ng-container>

                <ng-container matColumnDef="source">
                    <th mat-header-cell *matHeaderCellDef>Source</th>
                    <td mat-cell *matCellDef="let row"> {{row.attendancesource}} </td>
                </ng-container>

                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="10">No data found</td>
                </tr>
            </table>
            <div style="text-align: center;padding-top: 20px;" *ngIf="List.length === 0">No data found in selected date
            </div>

            <!-- </div> -->
        </div>
    </div>
    <div style="border-left: 2px solid black; margin: 0 15px;"></div>

    <div fxFlex="45" fxLayout="column">
        <p><strong>First In :</strong></p>
        <div>
            <div class="avatar-upload" *ngFor="let url of imageurls; let i = index">
                <img class="avatar-preview" [src]="url.base64String" *ngIf="Inimage == null" />
                <img class="avatar-preview" [src]="Inimage" *ngIf="Inimage!= null" />
            </div>
        </div>
        <p><strong>Last Out :</strong>&nbsp;<span>{{lastOut | date:'dd-MM-yyyy HH:mm:ss'}}</span></p>
        <div>
            <div class="avatar-upload" *ngFor="let url of imageurls; let i = index">
                <img class="avatar-preview" [src]="url.base64String" *ngIf="Outimage == null" />
                <img class="avatar-preview" [src]="Outimage" *ngIf="Outimage!= null" />
            </div>
        </div>

    </div>


</div>