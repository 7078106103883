<div fxLayout="column">
    <mat-card class="tableP">
        <div *ngIf="!isNewEmployee">
            <mat-card style="background-color: #ffffff">
                <div fxLayoutAlign="start">
                    <span>
                        <button mat-icon-button (click)="backArrow()" style="height: 25px">
                            <mat-icon style="font-weight: bold" title="Back">arrow_back</mat-icon>
                        </button>
                    </span>
                </div>
                <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="30" fxFlex.xs="50" fxFlex.sm="50" fxLayoutAlign="center">
                        <label class="hoverable1" for="fileInput" title="Upload">
                            <img class="img1" *ngIf="!empImageurls" src="../../../assets/img/profile.jpg"
                                alt="user-img">
                            <img class="img1" *ngIf="empImageurls" [src]="empImageurls">
                            <div class="hover-text"><mat-icon>camera_enhance</mat-icon></div>
                            <div class="background"></div>
                            <input id="fileInput" accept='image/*' type='file' (change)="onSelectFile2($event)">
                        </label>
                    </div>

                    <div fxFlex="70" fxFlex.xs="50" fxFlex.sm="50" fxLayoutAlign="start">
                        <div fxLayout="row" fxLayout.xs="column" style="width: 100%">
                            <div fxLayout="column" fxLayoutAlign="center" ngStyle.gt-md="width: 45%" ngStyle.xs="100%">
                                <p class="subh">
                                    Name
                                    <span class="heading" style="padding-left: 15%">{{
                                        employeeNameh | titlecase
                                        }}</span>
                                </p>
                                <p class="subh">
                                    Designation
                                    <span class="heading1" style="padding-left: 8px">{{
                                        employeeDesignation
                                        }}</span>
                                </p>
                            </div>
                            <div fxLayout="column" fxLayoutAlign="center" ngStyle.gt-md="width: 50%" ngStyle.xs="100%">
                                <p class="subh">
                                    Mobile
                                    <span class="heading1" style="padding-left: 40px">{{ employeeMobile }}
                                    </span>
                                </p>
                                <p class="subh">
                                    Joining Date
                                    <span class="heading1" style="padding-left: 5px">
                                        {{ employeeJoinDate | date : "dd-MM-YYYY" }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div fxLayoutAlign="start" *ngIf="isNewEmployee">
            <span>
                <button mat-icon-button (click)="backArrow()" style="height: 25px">
                    <mat-icon style="font-weight: bold" title="Back">arrow_back</mat-icon>
                </button>
            </span>
        </div>
        <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedtab.value"
            (selectedTabChange)="tabClick($event)" (selectedIndexChange)="selectedtab.setValue($event)"
            mat-stretch-tabs>
            <!-- personal info tab -->
            <mat-tab label="Personal Info">
                <form [formGroup]="personalInfoForm">
                    <mat-card style="padding: 1%">
                        <!-- personal information -->
                        <div class="section1"><b>Personal Information</b></div>
                        <mat-divider></mat-divider>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-top: 1%">
                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>First Name</mat-label>
                                <input type="text" formControlName="firstname" #firstname onlyAlphabets matInput
                                    placeholder="" maxlength="100" required />
                                <mat-error class="reg-error" *ngIf="personalInfoForm.controls.firstname.errors?.required ||
                                personalInfoForm.controls.firstname.errors?.['whitespace']">
                                    {{ EM1 }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Middle Name</mat-label>
                                <input type="text" onlyAlphabets formControlName="middlename" matInput placeholder=""
                                    maxlength="100" />
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Last Name</mat-label>
                                <input formControlName="lastname" onlyAlphabets matInput placeholder="" maxlength="100"
                                    required />
                                <mat-error class="reg-error" *ngIf="personalInfoForm.controls.lastname.errors?.required ||
                                personalInfoForm.controls.lastname.errors?.['whitespace']">
                                    {{ EM1 }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Date of Birth</mat-label>
                                <input required matInput readonly [matDatepicker]="dateofbirth" [min]="minDate"
                                    [max]="maxBirthDate" placeholder="DD-MM-YYYY" formControlName="dateofbirth"
                                    (click)="dateofbirth.open()" />
                                <mat-datepicker-toggle class="datepicker" matSuffix [for]="dateofbirth">
                                </mat-datepicker-toggle>
                                <mat-datepicker #dateofbirth></mat-datepicker>
                                <mat-error class="reg-error"
                                    *ngIf="personalInfoForm.controls.dateofbirth.errors?.required">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field *ngIf="employeeCode != undefined" fxFlex.xs="100" fxFlex="50"
                                class="mx-2 my-2" appearance="outline">
                                <mat-label>Blood Group</mat-label>
                                <mat-select formControlName="bloodgroup">
                                    <ng-container>
                                        <div *ngFor="let b of bloodGroupdetails">
                                            <mat-option value="{{ b.id }}">{{
                                                b.bloodgroup
                                                }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field *ngIf="employeeCode == undefined" fxFlex.xs="100" fxFlex="50"
                                class="mx-2 my-2" appearance="outline">
                                <mat-label>Blood Group</mat-label>
                                <mat-select formControlName="bloodgroup">
                                    <ng-container>
                                        <div *ngFor="let b of bloodGroupdetails">
                                            <mat-option [value]="b.id">{{ b.bloodgroup }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Gender</mat-label>
                                <mat-select formControlName="gender" required>
                                    <ng-container>
                                        <div *ngFor="let b of genderDetails">
                                            <mat-option [value]="b.id">{{ b.gender }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="reg-error" *ngIf="personalInfoForm.controls.gender.errors?.required">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Marital Status</mat-label>
                                <mat-select formControlName="maritalstatus" required>
                                    <ng-container>
                                        <div *ngFor="let b of maritalStatusDetails">
                                            <mat-option [value]="b.id">{{
                                                b.maritalstatus
                                                }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="reg-error" *ngIf="
                    personalInfoForm.controls.maritalstatus.errors?.required
                  ">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Aadhar Number</mat-label>
                                <input #aadharnumber type="text" onlyNumber onlyNumber formControlName="aadharNumber"
                                    matInput maxlength="12" minlength="12" placeholder="" />
                                <mat-error class="reg-error" *ngIf="
                    personalInfoForm.controls.aadharNumber.errors?.minlength ||
                    personalInfoForm.controls.aadharNumber.errors?.maxlength
                  ">
                                    Please enter 12 digit valid number
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Personal Email</mat-label>
                                <input type="text" formControlName="personalemail" matInput placeholder=""
                                    maxlength="50" />
                                <mat-error class="reg-error" *ngIf="
                    personalInfoForm.controls.personalemail.errors?.pattern
                  ">
                                    Please enter valid email
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex.xs="100" fxFlex="50" fxFlex.sm="50" class="mx-2 my-2"
                                appearance="outline">
                                <mat-label>Languages Spoken</mat-label>
                                <input type="text" formControlName="spokenLanguages" matInput maxlength="100"
                                    onlyAlphabets />
                            </mat-form-field>
                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Mobile Number</mat-label>
                                <input type="text" formControlName="mobileNo" matInput placeholder="" maxlength="10"
                                    minlength="10" onlyNumber required />
                                <mat-error class="con-error"
                                    *ngIf="personalInfoForm.controls.mobileNo.errors?.required">
                                    {{ EM1 }}
                                </mat-error>
                                <mat-error class="reg-error" *ngIf="
                    personalInfoForm.controls.mobileNo.errors?.minlength ||
                    personalInfoForm.controls.mobileNo.errors?.maxlength ||
                    personalInfoForm.controls.mobileNo.errors?.pattern
                  ">
                                    Please enter valid number
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Emergency Contact Number</mat-label>
                                <input type="text" onlyNumber formControlName="alternateMobileNo" matInput
                                    placeholder="" maxlength="10" minlength="10" onlyNumber required />
                                <mat-error class="con-error" *ngIf="
                    personalInfoForm.controls.alternateMobileNo.errors?.required
                  ">
                                    {{ EM1 }}
                                </mat-error>
                                <mat-error class="reg-error" *ngIf="
                    personalInfoForm.controls.alternateMobileNo.errors
                      ?.minlength ||
                    personalInfoForm.controls.alternateMobileNo.errors
                      ?.maxlength ||
                    personalInfoForm.controls.alternateMobileNo.errors?.pattern
                  ">
                                    Please enter valid number
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="section"><b>Work Information</b></div>
                        <mat-divider></mat-divider>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-top: 1%">
                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Joining Date</mat-label>
                                <input required matInput readonly [matDatepicker]="joinDate" [min]="minJoinDate"
                                    [max]="joiningmaxDate" placeholder="DD-MM-YYYY" formControlName="joinDate"
                                    (click)="joinDate.open()" />
                                <mat-datepicker-toggle class="datepicker" matSuffix [for]="joinDate">
                                </mat-datepicker-toggle>
                                <mat-datepicker #joinDate></mat-datepicker>
                                <mat-error class="reg-error"
                                    *ngIf="personalInfoForm.controls.joinDate.errors?.required">
                                    Please select an option
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Employee ID</mat-label>
                                <input readonly formControlName="empid" matInput placeholder="" />
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Office Email</mat-label>
                                <input type="text" maxlength="100" formControlName="officeemail" matInput
                                    placeholder="" />
                                <mat-error class="con-error"
                                    *ngIf="personalInfoForm.controls.officeemail.errors?.pattern">
                                    Please enter valid email
                                </mat-error>
                            </mat-form-field>

                            <!-- <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                            <mat-label>Employee Status</mat-label>
                            <mat-select formControlName="empStatus" required>
                                <ng-container>
                                    <div *ngFor="let b of statusList">
                                        <mat-option [value]="b.id">{{b.name}}</mat-option>
                                    </div>
                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error"
                                *ngIf="personalInfoForm.controls.empStatus.errors?.required">
                                {{EM2}}
                            </mat-error>
                        </mat-form-field> -->
                        </div>

                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                <mat-label>Employment Type</mat-label>
                                <mat-select required formControlName="employmentType">
                                    <input matInput [formControl]="searchControl" type="text" placeholder="  Search"
                                        class="search-align">
                                    <ng-container>
                                        <div *ngFor="let b of filteredEmpTypes">
                                            <mat-option [value]="b.id">{{
                                                b.employmenttype
                                                }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="con-error" *ngIf="
                    personalInfoForm.controls.employmentType.errors?.required
                  ">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>
                            <!-- <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Role</mat-label>
                                <mat-select required formControlName="usertype" [disabled]="editRoles">
                                    <ng-container>
                                        <div *ngFor="let b of availableRole">
                                            <mat-option [value]="b.id">{{ b.name }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="con-error"
                                    *ngIf="personalInfoForm.controls.usertype.errors?.required">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field> -->
                            <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                <mat-label>Role</mat-label>
                                <mat-select (openedChange)="openedSearch($event)" [compareWith]="compareFn" required
                                    formControlName="usertype" multiple>
                                    <mat-select-trigger>
                                        <span *ngFor="
                        let item of personalInfoForm.controls.usertype.value
                      ">
                                            {{ item.name ? item.name : item.usertype }} ,
                                        </span>
                                    </mat-select-trigger>
                                    <div class="select-container" *ngIf="flag">
                                        <input matInput [formControl]="searchControlforRole" type="text"
                                            placeholder="  Search" class="search-align">
                                        <mat-option (onSelectionChange)="selectedRolesChange($event)"
                                            *ngFor="let role of filteredRoles" [value]="role">{{ role.name
                                            }}</mat-option>
                                    </div>
                                    <div class="select-container" *ngIf="!flag">
                                        <input matInput [formControl]="searchControlforRole" type="text"
                                            placeholder="  Search" class="search-align">
                                        <mat-option (onSelectionChange)="selectedRolesChange($event)"
                                            *ngFor="let role of filteredRoles" [value]="role">{{ role.name }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                                <mat-error class="con-error"
                                    *ngIf="personalInfoForm.controls.usertype.errors?.required">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="33" class="mx-2 my-2" appearance="outline">
                                <mat-label>Company Location</mat-label>
                                <mat-select required formControlName="companylocation">
                                    <input matInput [formControl]="searchControlforloc" type="text"
                                        placeholder="  Search" class="search-align">
                                    <ng-container>
                                        <div *ngFor="let b of filteredLocations">
                                            <mat-option [value]="b.id">{{ b.location }} - {{ b.cityname }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="con-error" *ngIf="
                    personalInfoForm.controls.companylocation.errors?.required
                  ">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Department</mat-label>
                                <mat-select required formControlName="department"
                                    (selectionChange)="getMappedDesignations()">
                                    <div fxLayoutAlign="space between center">
                                        <input matInput [formControl]="searchControlforDep" type="text"
                                            placeholder=" Search" class="search-align">
                                        <button mat-icon-button style="padding-right: 40px;"
                                            (click)="addNew('Department')" matTooltip="Add New Department">
                                            <mat-icon
                                                style="font-size: 30px;overflow: visible; color: #28acaf;">add_circle</mat-icon>
                                        </button>
                                    </div>
                                    <ng-container *ngIf="filteredDep && filteredDep.length;else noOptions ">
                                        <mat-option *ngFor="let b of filteredDep" [value]="b.id">{{
                                            b.deptname }}
                                        </mat-option>
                                    </ng-container>
                                    <ng-template #noOptions>
                                        <mat-option disabled>No departments found</mat-option>
                                    </ng-template>
                                </mat-select>
                                <mat-error class="con-error"
                                    *ngIf="personalInfoForm.controls.department.errors?.required">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Designation</mat-label>

                                <mat-select required formControlName="designation">
                                    <div fxLayoutAlign="space between center">
                                        <input matInput [formControl]="searchControlforDesignation" type="text"
                                            placeholder=" Search" class="search-align">
                                        <button mat-icon-button style="padding-right: 40px;"
                                            (click)="addNew('Designation')" matTooltip="Add New Designation">
                                            <mat-icon
                                                style="font-size: 30px;overflow: visible; color: #28acaf;">add_circle</mat-icon>
                                        </button>
                                    </div>
                                    <ng-container
                                        *ngIf="filteredDesignations && filteredDesignations.length; else noOptionsdesignations">

                                        <div *ngIf="ismappingEnable">
                                            <mat-option [value]="b.designation_id"
                                                *ngFor="let b of filteredDesignations">{{b.designation_name}}</mat-option>
                                        </div>
                                        <div *ngIf="!ismappingEnable">
                                            <mat-option [value]="b.id"
                                                *ngFor="let b of filteredDesignations">{{b.designation}}</mat-option>
                                        </div>
                                    </ng-container>
                                    <ng-template #noOptionsdesignations>
                                        <mat-option disabled>No designations found</mat-option>
                                    </ng-template>
                                </mat-select>
                                <mat-error class="con-error"
                                    *ngIf="personalInfoForm.controls.designation.errors?.required">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>

                            <!-- <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Finance Manager</mat-label>
                                <mat-select formControlName="fmanager" required>
                                    <ng-container *ngFor="let b of availableFinanceManagers">
                                        <mat-option [value]="b.id">{{
                                            b.employee_name
                                            }}</mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="con-error" *ngIf="
                    personalInfoForm.controls.fmanager.errors?.required
                  ">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field> -->
                            <mat-form-field fxFlex.xs="100" fxFlex="32" fxFlex.lt-lg="33" class="mx-2 my-2"
                                appearance="outline">
                                <mat-label>Notice Period</mat-label>
                                <input type="text" readonly formControlName="noticePeriod" matInput placeholder="" />
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <!-- <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>HR Manager</mat-label>
                                <mat-select formControlName="hrmanager" required>
                                    <ng-container *ngFor="let b of availableHRManagers">
                                        <mat-option [value]="b.id">{{
                                            b.employee_name
                                            }}</mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="con-error" *ngIf="
                    personalInfoForm.controls.hrmanager.errors?.required
                  ">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field> -->
                            <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                <mat-label>Reporting Manager Department</mat-label>

                                <mat-select formControlName="rdepartment">
                                    <input matInput [formControl]="searchControlforRMDep" type="text"
                                        placeholder="  Search" class="search-align">
                                    <mat-option> --Select-- </mat-option>
                                    <ng-container>
                                        <div *ngFor="let b of filteredRMDep">
                                            <mat-option [value]="b.id">{{ b.deptname }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                                <!-- <mat-error class="con-error"
                                    *ngIf="personalInfoForm.controls.rdepartment.errors?.required">
                                    {{ EM2 }}
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="31.5" class="mx-2 my-2" appearance="outline">
                                <mat-label>Reporting Manager</mat-label>
                                <mat-select formControlName="reportingmanager" (selectionChange)="onChangeRM($event)"
                                    required>
                                    <input matInput class="search-align" placeholder="Search"
                                        [formControl]="searchReportingManager">
                                    <ng-container *ngFor="let b of filteredReportingManagers">
                                        <mat-option [value]="b.id">{{ b.employee_name }}</mat-option>
                                    </ng-container>
                                    <mat-option value="Self" *ngIf="isself">Self</mat-option>
                                </mat-select>
                                <mat-error class="con-error" *ngIf="
                    personalInfoForm.controls.reportingmanager.errors?.required
                  ">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex.xs="100" fxFlex="32" fxFlex.lt-lg="33" class="mx-2 my-2"
                                appearance="outline">
                                <mat-label>Notice Period</mat-label>
                                <input type="text" readonly formControlName="noticePeriod" matInput placeholder="" />
                            </mat-form-field>
                        </div> -->
                        <!-- contact -->
                        <div class="section"><b>Personal Contact Information</b></div>
                        <mat-divider></mat-divider>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end"
                            style="padding-top: 1%; padding-bottom: 1%">
                            <mat-slide-toggle style="padding-right: 11px" formControlName="checked"
                                color="primary"><b>Same as residence address</b></mat-slide-toggle>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Residence Address</mat-label>
                                <input type="text" formControlName="raddress" matInput placeholder="" maxlength="100"
                                    required />
                                <mat-error class="con-error"
                                    *ngIf="personalInfoForm.controls.raddress.errors?.required">
                                    {{ EM1 }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Permanent Address</mat-label>
                                <input type="text" formControlName="paddress" matInput placeholder="" maxlength="100" />
                                <!-- <input *ngIf="isview" formControlName="lastname" matInput placeholder="" readonly> -->
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Country</mat-label>
                                <mat-select required formControlName="rcountry" required>
                                    <input matInput [formControl]="searchControlforRCoun" type="text"
                                        placeholder="  Search" class="search-align">
                                    <ng-container>
                                        <div *ngFor="let b of filteredRCountryList">
                                            <mat-option [value]="b.id">{{ b.country }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="con-error"
                                    *ngIf="personalInfoForm.controls.rcountry.errors?.required">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Country</mat-label>
                                <mat-select formControlName="pcountry">
                                    <input matInput [formControl]="searchControlforPCoun" type="text"
                                        placeholder="  Search" class="search-align">
                                    <ng-container>
                                        <div *ngFor="let b of filteredPCountryList">
                                            <mat-option [value]="b.id">{{ b.country }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>State</mat-label>
                                <mat-select formControlName="rstate" required>
                                    <input matInput [formControl]="searchControlforRState" type="text"
                                        placeholder="  Search" class="search-align">
                                    <ng-container>
                                        <div *ngFor="let b of filteredRStates">
                                            <mat-option [value]="b.id">{{ b.state }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="con-error" *ngIf="personalInfoForm.controls.rstate.errors?.required">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>State</mat-label>
                                <mat-select formControlName="pstate">
                                    <input matInput [formControl]="searchControlforPState" type="text"
                                        placeholder="  Search" class="search-align">
                                    <ng-container>
                                        <div *ngFor="let b of filteredPStates">
                                            <mat-option [value]="b.id">{{ b.state }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>City</mat-label>
                                <mat-select formControlName="rcity" required>
                                    <input matInput [formControl]="searchControlforRCity" type="text"
                                        placeholder="  Search" class="search-align">
                                    <ng-container>
                                        <div *ngFor="let b of filteredRcity">
                                            <mat-option [value]="b.id">{{ b.location }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="con-error" *ngIf="personalInfoForm.controls.rcity.errors?.required">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>City</mat-label>
                                <mat-select formControlName="pcity">
                                    <input matInput [formControl]="searchControlforPCity" type="text"
                                        placeholder="  Search" class="search-align">
                                    <ng-container>
                                        <div *ngFor="let b of filteredPcity">
                                            <mat-option [value]="b.id">{{ b.location }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Pincode</mat-label>
                                <input type="text" onlyNumber formControlName="rpincode" matInput placeholder=""
                                    maxlength="6" minlength="6" required onlyNumber />
                                <mat-error class="con-error"
                                    *ngIf="personalInfoForm.controls.rpincode.errors?.required">
                                    {{ EM1 }}
                                </mat-error>
                                <mat-error class="reg-error"
                                    *ngIf="personalInfoForm.controls.rpincode.errors?.minlength">
                                    Pincode should be 6 digits
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Pincode</mat-label>
                                <input type="text" onlyNumber formControlName="ppincode" matInput placeholder=""
                                    maxlength="6" minlength="6" onlyNumber />
                                <mat-error class="reg-error"
                                    *ngIf="personalInfoForm.controls.ppincode.errors?.minlength">
                                    Pincode should be 6 digits
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <form [formGroup]="candidateFamilyForm">
                            <div class="section" fxLayout="row">
                                <span style="padding-top: 3px"><b>Family Details : </b></span>
                                <span style="padding-left: 1%">
                                    <mat-icon title="Add" style="color: #28acaf; font-size: 35px; overflow: visible"
                                        (click)="addingFamilyView()">add_box</mat-icon>
                                </span>
                            </div>
                            <div *ngIf="addFamilyView">
                                <mat-divider></mat-divider>
                                <br />
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Name</mat-label>
                                        <input type="text" formControlName="familyfirstname" matInput placeholder=""
                                            maxlength="100" required />
                                        <mat-error class="con-error" *ngIf="
                        candidateFamilyForm.controls.familyfirstname.errors
                          ?.required
                      ">
                                            {{ EM1 }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Contact Number</mat-label>
                                        <input type="text" formControlName="familycontact" matInput placeholder=""
                                            maxlength="10" minlength="10" onlyNumber />
                                        <mat-error class="reg-error" *ngIf="
                        candidateFamilyForm.controls.familycontact.errors
                          ?.minlength ||
                        candidateFamilyForm.controls.familycontact.errors
                          ?.maxlength ||
                        candidateFamilyForm.controls.familycontact.errors
                          ?.pattern
                      ">
                                            Please enter valid number
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Gender</mat-label>
                                        <mat-select formControlName="familygender" required>
                                            <ng-container>
                                                <div *ngFor="let b of genderDetails">
                                                    <mat-option [value]="b">{{ b.gender }}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error" *ngIf="
                        candidateFamilyForm.controls.familygender.errors
                          ?.required
                      ">
                                            {{ EM2 }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign.xs="center">
                                    <mat-form-field fxFlex.xs="100" fxFlex.sm="50" fxFlex="32" class="mx-2 my-2"
                                        appearance="outline">
                                        <mat-label>Relationship</mat-label>
                                        <mat-select required formControlName="relation">
                                            <ng-container>
                                                <div *ngFor="let b of employeeRelationship">
                                                    <mat-option [value]="b">{{
                                                        b.relationship
                                                        }}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error" *ngIf="
                        candidateFamilyForm.controls.relation.errors?.required
                      ">
                                            {{ EM2 }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex.sm="50" fxFlex="32" class="mx-2 my-2"
                                        appearance="outline">
                                        <mat-label>Status</mat-label>
                                        <mat-select required formControlName="familystatus">
                                            <ng-container>
                                                <div>
                                                    <mat-option value="Alive">Alive</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>

                                    <div fxLayout="row" fxLayoutAlign="center">
                                        <span style="padding-top: 5%">
                                            <button *ngIf="!isfamilyedit" class="btn btn-primary mr-2 mb-2"
                                                (click)="addfamily()">
                                                <span> Add </span>
                                            </button>

                                            <button *ngIf="isfamilyedit" class="btn btn-primary mr-2 mb-2"
                                                (click)="addfamily()">
                                                <span> Update </span>
                                            </button>
                                            <button class="btn btn-danger mr-2 mb-2" (click)="clearfamily()">
                                                Clear
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="mat-elevation-z1" *ngIf="familyDetails.length > 0">
                                <table mat-table [dataSource]="familyDataSource">
                                    <ng-container matColumnDef="position">
                                        <th mat-header-cell *matHeaderCellDef>S. No.</th>
                                        <td mat-cell *matCellDef="let data; let i = index">
                                            {{ i + 1 }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef>Name</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.firstname }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="relation">
                                        <th mat-header-cell *matHeaderCellDef>Relationship</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.relationshipname }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="gender">
                                        <th mat-header-cell *matHeaderCellDef>Gender</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.gendername }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="contact">
                                        <th mat-header-cell *matHeaderCellDef>Contact Number</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{
                                            data.contactnumber != "null" ? data.contactnumber : ""
                                            }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef>Status</th>
                                        <td mat-cell *matCellDef="let data">{{ data.status }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element; let i = index">
                                            <button mat-icon-button (keyup.enter)="editfamily(i)"
                                                *ngIf="!isviewemployee && element.status == 'Alive'">
                                                <mat-icon title="Edit" (click)="editfamily(i)">
                                                    edit
                                                </mat-icon>
                                            </button>
                                            <button mat-icon-button (keyup.enter)="deleteFamilyPopup(i)"
                                                *ngIf="!isviewemployee">
                                                <mat-icon title="Delete" (click)="deleteFamilyPopup(i)">
                                                    delete</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="familyTableColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: familyTableColumns"></tr>
                                </table>
                            </div>
                        </form>
                    </mat-card>
                    <mat-divider></mat-divider>
                    <br />
                    <div fxLayoutAlign="center">
                        <button class="btn btn-primary custom mr-2 mb-2" (click)="savePersonalInfo()" type="submit">
                            <span>Save & Continue</span>
                        </button>
                        <br />
                    </div>
                </form>
            </mat-tab>
            <!-- job tab -->
            <mat-tab label="Contract/Promotions" [disabled]="isNewEmployee">
                <div fxLayout="column">
                    <mat-card style="padding-left: 1%">
                        <!-- contract with promotions -->
                        <div *ngIf="isContractData">
                            <form [formGroup]="employeeJobForm" *ngIf="isContractData">
                                <div class="section"><b>Employee Contract</b></div>
                                <mat-divider></mat-divider>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center"
                                    style="padding-top: 2%">
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Contract Name</mat-label>
                                        <input type="text" formControlName="contractName" matInput placeholder=""
                                            maxlength="100" required />
                                        <mat-error class="con-error" *ngIf="
                        employeeJobForm.controls.contractName.errors?.required
                      ">
                                            {{ EM1 }}
                                        </mat-error>
                                    </mat-form-field>
                                    <!-- new date -->
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Start Date</mat-label>
                                        <input required matInput readonly [matDatepicker]="contractStartDate"
                                            [max]="edmaxDate" placeholder="DD-MM-YYYY"
                                            formControlName="contractStartDate" (click)="contractStartDate.open()" />
                                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="contractStartDate">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #contractStartDate></mat-datepicker>
                                        <mat-error class="con-error" *ngIf="
                        employeeJobForm.controls.contractStartDate.errors
                          ?.required
                      ">
                                            {{ EM2 }}
                                        </mat-error>
                                    </mat-form-field>
                                    <!-- start date -->
                                    <mat-form-field fxFlex.xs="100%" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>End Date</mat-label>
                                        <input matInput readonly [matDatepicker]="contractEndDate"
                                            [min]="mincontarctDate" [max]="maxDate" placeholder="DD-MM-YYYY"
                                            formControlName="contractEndDate" (click)="contractEndDate.open()"
                                            required />
                                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="contractEndDate">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #contractEndDate></mat-datepicker>
                                        <mat-error class="con-error" *ngIf="
                        employeeJobForm.controls.contractEndDate.errors
                          ?.required
                      ">
                                            {{ EM2 }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                    <!-- <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Attach File</mat-label>
                                    <input type="text" onlyNumber formControlName="contractFile" matInput placeholder=""
                                        maxlength="6" />
                                </mat-form-field> -->
                                    <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Contract Notes</mat-label>
                                        <textarea formControlName="contractNotes" matInput placeholder=""
                                            maxlength="400"></textarea>
                                    </mat-form-field>
                                </div>

                                <div class="section"><b>Promotions</b></div>
                                <mat-divider></mat-divider>
                                <form [formGroup]="promotionsForm">
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center"
                                        style="padding-top: 2%">
                                        <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2"
                                            appearance="outline">
                                            <mat-label>Description</mat-label>
                                            <input type="text" formControlName="newDescription" matInput placeholder=""
                                                maxlength="400" />
                                        </mat-form-field>

                                        <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2"
                                            appearance="outline">
                                            <mat-label>New Salary</mat-label>
                                            <input type="text" onlyNumber onlyNumber formControlName="newSalary"
                                                matInput placeholder="" maxlength="8" />
                                        </mat-form-field>

                                        <mat-form-field fxFlex.xs="100%" fxFlex="50" class="mx-2 my-2"
                                            appearance="outline">
                                            <mat-label>Effective Date</mat-label>
                                            <input matInput readonly [matDatepicker]="effectiveDate" [min]="minDate"
                                                [max]="maxDate" placeholder="DD-MM-YYYY" formControlName="effectiveDate"
                                                (click)="effectiveDate.open()" />
                                            <mat-datepicker-toggle class="datepicker" matSuffix [for]="effectiveDate">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #effectiveDate></mat-datepicker>
                                        </mat-form-field>

                                        <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2"
                                            appearance="outline">
                                            <mat-label>Annual Salary</mat-label>
                                            <input type="text" onlyNumber maxlength="8" onlyNumber
                                                formControlName="annualSalary" matInput placeholder="" />
                                        </mat-form-field>
                                    </div>

                                    <div class="mat-elevation-z1" *ngIf="promotionsGetList.length > 0">
                                        <table mat-table [dataSource]="promotionsDataSource">
                                            <ng-container matColumnDef="sno">
                                                <th mat-header-cell *matHeaderCellDef>S No</th>
                                                <td mat-cell *matCellDef="let data; let i = index">
                                                    {{ i + 1 }}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="salary">
                                                <th mat-header-cell *matHeaderCellDef>Annual Salary</th>
                                                <td mat-cell *matCellDef="let data">
                                                    {{ data.annualsalary }}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="fromDate">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    Effective Date
                                                </th>
                                                <td mat-cell *matCellDef="let data">
                                                    {{ data.effectivedate | date : "dd-MM-yyyy" }}
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="promotionsTableColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: promotionsTableColumns"></tr>
                                        </table>
                                    </div>
                                </form>
                                <mat-divider></mat-divider>
                                <br />
                                <div fxLayoutAlign="center">
                                    <button class="btn btn-primary custom mr-2 mb-2" (click)="saveJobDetails()">
                                        <span>Save & Continue</span>
                                    </button>
                                    <br />
                                </div>
                            </form>
                        </div>

                        <!-- only promotions -->
                        <div *ngIf="isPromotionsOnly">
                            <div class="section"><b>Promotions</b></div>
                            <mat-divider></mat-divider>
                            <form [formGroup]="promotionsForm">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center"
                                    style="padding-top: 1%">
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Description</mat-label>
                                        <input type="text" formControlName="newDescription" matInput placeholder=""
                                            required maxlength="400" />
                                        <mat-error class="con-error" *ngIf="
                        promotionsForm.controls.newDescription.errors?.required
                      ">
                                            {{ EM1 }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>New Salary</mat-label>
                                        <input type="text" onlyNumber onlyNumber formControlName="newSalary" matInput
                                            placeholder="" maxlength="8" required />
                                        <mat-error class="con-error"
                                            *ngIf="promotionsForm.controls.newSalary.errors?.required">
                                            {{ EM1 }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100%" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Effective Date</mat-label>
                                        <input matInput readonly [matDatepicker]="effectiveDate" [min]="minDate"
                                            [max]="maxDate" placeholder="DD-MM-YYYY" formControlName="effectiveDate"
                                            required (click)="effectiveDate.open()" />
                                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="effectiveDate">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #effectiveDate></mat-datepicker>
                                        <mat-error class="con-error" *ngIf="
                        promotionsForm.controls.effectiveDate.errors?.required
                      ">
                                            Please select date
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Annual Salary</mat-label>
                                        <input type="text" onlyNumber maxlength="8" onlyNumber
                                            formControlName="annualSalary" matInput placeholder="" required />
                                        <mat-error class="con-error" *ngIf="
                        promotionsForm.controls.annualSalary.errors?.required
                      ">
                                            {{ EM1 }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <mat-divider></mat-divider>
                                <br />
                                <div fxLayoutAlign="center">
                                    <button class="btn btn-primary custom mr-2 mb-2" (click)="saveJobDetails()">
                                        <span>Save & Continue</span>
                                    </button>
                                    <br />
                                </div>
                            </form>
                            <div class="mat-elevation-z1" *ngIf="promotionsGetList.length > 0">
                                <table mat-table [dataSource]="promotionsDataSource">
                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef>S No</th>
                                        <td mat-cell *matCellDef="let data; let i = index">
                                            {{ i + 1 }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="salary">
                                        <th mat-header-cell *matHeaderCellDef>Annual Salary</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.annualsalary }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="fromDate">
                                        <th mat-header-cell *matHeaderCellDef>Start Date</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.effectivedate | date : "dd-MM-yyyy" }}
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="promotionsTableColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: promotionsTableColumns"></tr>
                                </table>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </mat-tab>
            <!-- employement tab -->
            <mat-tab label="Work Experience" [disabled]="isNewEmployee">
                <div fxLayout="column">
                    <mat-card style="padding-left: 1%">
                        <form [formGroup]="experienceForm">
                            <div class="section"><b>Work Experience</b></div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-top: 1%">
                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Company Name</mat-label>
                                    <input type="text" formControlName="companyName" onlyAlphabets noLeadingWhitespace
                                        matInput maxlength="100" required />
                                    <mat-error class="con-error"
                                        *ngIf="experienceForm.controls.companyName.errors?.required">
                                        {{ EM1 }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Designation</mat-label>
                                    <input type="text" required formControlName="designation" onlyAlphabets matInput
                                        noLeadingWhitespace maxlength="30" />
                                    <mat-error class="reg-error" *ngIf="
                      experienceForm.controls.designation.hasError('required')
                    ">
                                        {{ EM1 }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100%" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>From Date</mat-label>
                                    <input matInput required readonly [matDatepicker]="expFromDate" [min]="minDate"
                                        [max]="expmaxDate" placeholder="DD-MM-YYYY" formControlName="expFromDate"
                                        (click)="expFromDate.open()" (dateChange)="onExpDateChange()" />
                                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="expFromDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #expFromDate></mat-datepicker>
                                    <mat-error class="con-error"
                                        *ngIf="experienceForm.controls.expFromDate.errors?.required">
                                        Please select date
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100%" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                    <mat-label>To Date</mat-label>
                                    <input required matInput readonly [matDatepicker]="expToDate"
                                        [min]="minExperienceDate" [max]="expmaxDate" placeholder="DD-MM-YYYY"
                                        formControlName="expToDate" (click)="expToDate.open()" />
                                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="expToDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #expToDate></mat-datepicker>
                                    <mat-error class="con-error"
                                        *ngIf="experienceForm.controls.expToDate.errors?.required">
                                        Please select date
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="50" fxFlex.xs="100">
                                    <mat-form-field fxFlex="100" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Job Description</mat-label>
                                        <textarea formControlName="jobDescription" matInput noLeadingWhitespace
                                            maxlength="400"></textarea>
                                    </mat-form-field>
                                </div>
                                <!-- <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="start center">
                                <span>
                                    <button class="btn btn-primary mr-2 mb-2" (click)="addWorkExperience()">
                                        <span *ngIf="!isExperienceEdit"> Submit </span>
                                        <span *ngIf="isExperienceEdit"> Update </span>
                                    </button>
                                    <button *ngIf="!isExperienceEdit" class="btn btn-primary custom1 mr-2 mb-2" (click)="workExperienceSubmitAdd()">
                                        <span> Submit & Add </span>
                                    </button>
                                    <button class="btn btn-danger mr-2 mb-2" (click)="clearWorkExperience()">
                                        Clear
                                    </button>
                                </span>
                            </div> -->
                            </div>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
                                <span>
                                    <button class="btn btn-primary mr-2 mb-2" (click)="addWorkExperience()">
                                        <span *ngIf="!isExperienceEdit"> Save </span>
                                        <span *ngIf="isExperienceEdit"> Update </span>
                                    </button>
                                    <button *ngIf="!isExperienceEdit" class="btn btn-primary custom1 mr-2 mb-2"
                                        (click)="workExperienceSubmitAdd()">
                                        <span> Save & Add</span>
                                    </button>
                                    <button class="btn btn-danger mr-2 mb-2" (click)="clearWorkExperience()">
                                        Clear
                                    </button>
                                </span>
                            </div>

                            <div class="mat-elevation-z1" *ngIf="workExperienceDetails.length > 0">
                                <table mat-table [dataSource]="workExperienceDataSource" class="w-100">
                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef>S No</th>
                                        <td mat-cell *matCellDef="let data; let i = index">
                                            {{ i + 1 }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="company">
                                        <th mat-header-cell *matHeaderCellDef>Company Name</th>
                                        <td mat-cell *matCellDef="let data" class="truncate-cell">
                                            {{ data.companyname }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="desig">
                                        <th mat-header-cell *matHeaderCellDef>Designation</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.designation }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="fromDate">
                                        <th mat-header-cell *matHeaderCellDef>From Date</th>
                                        <td mat-cell *matCellDef="let data" class="no-break">
                                            {{ data.fromdate | date : "dd-MM-yyyy" }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="toDate">
                                        <th mat-header-cell *matHeaderCellDef>To Date</th>
                                        <td mat-cell *matCellDef="let data" class="no-break">
                                            {{ data.todate | date : "dd-MM-yyyy" }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element; let i = index">
                                            <button mat-icon-button (keyup.enter)="editExperience(i)"
                                                *ngIf="!isviewemployee">
                                                <mat-icon title="Edit" (click)="editExperience(i)">
                                                    edit
                                                </mat-icon>
                                            </button>
                                            <button mat-icon-button (keyup.enter)="deleteExperiencePopup(i)"
                                                *ngIf="!isviewemployee">
                                                <mat-icon title="Delete" (click)="deleteExperiencePopup(i)">
                                                    delete</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="workTableColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: workTableColumns"></tr>
                                </table>
                            </div>
                            <br />
                            <!-- <div fxLayoutAlign="center">
                                <button class="btn btn-primary custom mr-2 mb-2"   (click)="saveWorkExperience()" type="submit">
                                    <span>Save & Continue</span>
                                </button>
                                <br />
                            </div> -->
                        </form>
                    </mat-card>
                </div>
            </mat-tab>

            <!-- Bank details tab -->
            <mat-tab label="Bank Details" [disabled]="isNewEmployee">
                <div fxLayout="column">
                    <mat-card style="padding-left: 1%">
                        <form [formGroup]="employementForm">
                            <div class="section"><b>Bank Details</b></div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-top: 1%">
                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Bank Name</mat-label>
                                    <input type="text" noLeadingWhitespace onlyAlphabets formControlName="bankName"
                                        matInput placeholder="" maxlength="50" required />
                                    <mat-error class="con-error"
                                        *ngIf="employementForm.controls.bankName.errors?.required">
                                        {{ EM1 }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Name as per bank account</mat-label>
                                    <input type="text" onlyAlphabets noLeadingWhitespace
                                        formControlName="bankAccountName" matInput placeholder="" maxlength="50"
                                        required />
                                    <mat-error class="con-error" *ngIf="
                      employementForm.controls.bankAccountName.errors?.required
                    ">
                                        {{ EM1 }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Account Number</mat-label>
                                    <input type="text" onlyNumber formControlName="bankAccountNumber" matInput
                                        placeholder="" maxlength="18" minlength="9" required />
                                    <mat-error class="con-error" *ngIf="
                      employementForm.controls.bankAccountNumber.errors
                        ?.required
                    ">
                                        {{ EM1 }}
                                    </mat-error>
                                    <mat-error class="con-error" *ngIf="
                      employementForm.controls.bankAccountNumber.errors
                        ?.minlength ||
                      employementForm.controls.bankAccountNumber.errors
                        ?.maxlength
                    ">
                                        Please enter valid account number
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                    <mat-label>IFSC Code</mat-label>
                                    <input type="text" maxlength="11" alphaNumericOnly formControlName="ifscCode"
                                        matInput placeholder="" required
                                        onkeyup="this.value = this.value.toUpperCase();" />
                                    <mat-error class="con-error"
                                        *ngIf="employementForm.controls.ifscCode.errors?.required">
                                        {{ EM1 }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Branch Name</mat-label>
                                    <input type="text" formControlName="branchName" matInput placeholder=""
                                        maxlength="50" required onlyAlphabets noLeadingWhitespace />
                                    <mat-error class="con-error"
                                        *ngIf="employementForm.controls.branchName.errors?.required">
                                        {{ EM1 }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>UAN Number</mat-label>
                                    <input type="text" onlyNumber formControlName="uanNumber" matInput minlength="12"
                                        maxlength="12" />
                                    <mat-error class="reg-error" *ngIf="
                      employementForm.controls.uanNumber.errors?.minlength ||
                      employementForm.controls.uanNumber.errors?.maxlength
                    ">
                                        Please enter 12 digits valid number
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                    <mat-label>PAN Number</mat-label>
                                    <input type="text" maxlength="10" alphaNumericOnly formControlName="panNumber"
                                        matInput onkeyup="this.value = this.value.toUpperCase();" />
                                    <mat-error class="con-error"
                                        *ngIf="employementForm.controls.panNumber.errors?.pattern">
                                        Please enter valid number,like ' ABCDE1234F '
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                    <mat-label>ESI Number</mat-label>
                                    <input matInput mask="00-00-000000-000-0000" formControlName="esiNumber"
                                        minlength="17" maxlength="21" />
                                    <mat-error class="reg-error"
                                        *ngIf="employementForm.controls.esiNumber.errors?.minlength">
                                        Please enter 17 digits valid number
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <mat-divider></mat-divider>
                            <br />
                            <div fxLayoutAlign="center">
                                <button class="btn btn-primary custom mr-2 mb-2" (click)="saveBankDetails()"
                                    type="submit">
                                    <span>Save & Continue</span>
                                </button>
                                <br />
                            </div>
                        </form>
                    </mat-card>
                </div>
            </mat-tab>

            <!-- education tab -->
            <mat-tab label="Education" [disabled]="isNewEmployee">
                <div fxLayout="column">
                    <mat-card style="padding-left: 1%">
                        <form [formGroup]="educationForm">
                            <div class="section"><b>Education</b></div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-top: 1%">
                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Course</mat-label>
                                    <input required type="text" maxlength="100" formControlName="course" matInput
                                        placeholder="" onlyAlphabets noLeadingWhitespace />
                                    <mat-error class="con-error" *ngIf="educationForm.controls.course.errors?.required">
                                        {{ EM1 }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Institute Name</mat-label>
                                    <input required type="text" maxlength="100" onlyAlphabets
                                        formControlName="instituteName" matInput noLeadingWhitespace />
                                    <mat-error class="con-error" *ngIf="
                      educationForm.controls.instituteName.errors?.required
                    ">
                                        {{ EM1 }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>From Date</mat-label>
                                    <input required matInput readonly [matDatepicker]="eduFromDate" [max]="edmaxDate"
                                        placeholder="DD-MM-YYYY" formControlName="eduFromDate"
                                        (click)="eduFromDate.open()" (dateChange)="onEduDateChange()" />
                                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="eduFromDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #eduFromDate></mat-datepicker>
                                    <mat-error class="con-error"
                                        *ngIf="educationForm.controls.eduFromDate.errors?.required">
                                        Please select date
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>To Date</mat-label>
                                    <input required matInput readonly [matDatepicker]="eduToDate"
                                        [min]="minEducationDate" [max]="edmaxDate" placeholder="DD-MM-YYYY"
                                        formControlName="eduToDate" (click)="eduToDate.open()" />
                                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="eduToDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #eduToDate></mat-datepicker>
                                    <mat-error class="con-error"
                                        *ngIf="educationForm.controls.eduToDate.errors?.required">
                                        Please select date
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center">
                                <button class="btn btn-primary mr-2 mb-2" (click)="addEducation()">
                                    <span *ngIf="!isEducationEdit"> Save </span>
                                    <span *ngIf="isEducationEdit"> Update </span>
                                </button>
                                <button *ngIf="!isEducationEdit" class="btn btn-primary custom1 mr-2 mb-2"
                                    (click)="educationSaveAdd()">
                                    <span> Save & Add </span>
                                </button>
                                <button class="btn btn-danger mr-2 mb-2" (click)="clearEducation()">
                                    Clear
                                </button>
                            </div>
                            <mat-divider></mat-divider>
                            <br />
                            <div class="mat-elevation-z1" *ngIf="educationDetails.length > 0">
                                <table mat-table [dataSource]="educationDataSource">
                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef>S No</th>
                                        <td mat-cell *matCellDef="let data; let i = index">
                                            {{ i + 1 }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="course">
                                        <th mat-header-cell *matHeaderCellDef>Course</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.course }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="college">
                                        <th mat-header-cell *matHeaderCellDef>Institute Name</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.institutename }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="fromDate">
                                        <th mat-header-cell *matHeaderCellDef>From Date</th>
                                        <td mat-cell *matCellDef="let data" class="no-break">
                                            {{ data.fromdate | date : "dd-MM-yyyy" }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="toDate">
                                        <th mat-header-cell *matHeaderCellDef>To Date</th>
                                        <td mat-cell *matCellDef="let data" class="no-break">
                                            {{ data.todate | date : "dd-MM-yyyy" }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element; let i = index">
                                            <button mat-icon-button (keyup.enter)="editEduction(i)"
                                                *ngIf="!isviewemployee">
                                                <mat-icon title="Edit" (click)="editEduction(i)">
                                                    edit
                                                </mat-icon>
                                            </button>
                                            <button mat-icon-button (keyup.enter)="deleteEducationPopup(i)"
                                                *ngIf="!isviewemployee">
                                                <mat-icon title="Delete" (click)="deleteEducationPopup(i)">
                                                    delete</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="educationTableColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: educationTableColumns"></tr>
                                </table>
                            </div>
                            <br />
                            <!-- <div fxLayoutAlign="center">
                            <button class="btn btn-primary custom mr-2 mb-2" (click)="saveEducation()" type="submit">
                                <span>Save & Continue</span>
                            </button>
                            <br />
                        </div> -->
                        </form>
                    </mat-card>
                </div>
            </mat-tab>

            <!-- document tab -->
            <mat-tab label="Documents" [disabled]="isNewEmployee">
                <div fxLayout="column" style="padding-left: 1%">
                    <form [formGroup]="documentsForm">
                        <div class="section"><b>Add Documents</b></div>
                        <mat-divider></mat-divider>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-top: 1%">
                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Document Name</mat-label>
                                <mat-select required formControlName="documentName">
                                    <ng-container>
                                        <div *ngFor="let b of documentTypeList">
                                            <mat-option value="{{ b.category }}">{{
                                                b.description
                                                }}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="reg-error"
                                    *ngIf="documentsForm.controls.documentName.errors?.required">
                                    {{ EM2 }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                <mat-label>Document Number</mat-label>
                                <input type="text" minlength="6" maxlength="30" formControlName="documentNumber"
                                    matInput noLeadingWhitespace required />
                                <mat-error class="reg-error"
                                    *ngIf="documentsForm.controls.documentNumber.errors?.required">
                                    {{ EM1 }}
                                </mat-error>
                                <mat-error class="reg-error" *ngIf="
                    documentsForm.controls.documentNumber.errors?.minlength
                  ">
                                    {{ EM22 }}
                                </mat-error>
                            </mat-form-field>

                            <div fxLayout="row" style="align-items: center">
                                <input type="file" title="" style="margin-left: 10px" *ngIf="!isedit"
                                    formControlName="attachedFile" (change)="onSelectFile($event)" single />

                                <button class="link" *ngIf="isedit">
                                    {{ editFileName }}
                                </button>

                                &nbsp;
                                <button mat-icon-button (keyup.enter)="deleteIcon()"
                                    *ngIf="documentsForm.controls.attachedFile.value">
                                    <mat-icon title="Delete" (click)="deleteIcon()">delete</mat-icon>
                                </button>
                                <button mat-icon-button (keyup.enter)="delete()" *ngIf="isedit">
                                    <mat-icon title="Remove" (click)="delete()">cancel</mat-icon>
                                </button>

                                &nbsp;
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center" ngStyle.xs="padding-top:1%">
                            <button class="btn btn-primary mr-2 mb-2" (click)="validateDocument()">
                                <span *ngIf="!isedit"> Save </span>
                                <span *ngIf="isedit"> Update </span>
                            </button>
                            <button class="btn btn-danger mr-2 mb-2" (click)="clearDock()">
                                Clear
                            </button>
                        </div>
                        <br />
                        <div class="mat-elevation-z1" *ngIf="documentDetails.length > 0">
                            <table mat-table [dataSource]="documentDataSource">
                                <ng-container matColumnDef="position">
                                    <th mat-header-cell *matHeaderCellDef>S No</th>
                                    <td mat-cell *matCellDef="let data; let i = index">
                                        {{ i + 1 }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="category">
                                    <th mat-header-cell *matHeaderCellDef>Document Name</th>
                                    <td mat-cell *matCellDef="let data">
                                        {{ data.description }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="number">
                                    <th mat-header-cell *matHeaderCellDef>Document Number</th>
                                    <td mat-cell *matCellDef="let data">
                                        {{ data.document_number }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef>Document</th>
                                    <td mat-cell *matCellDef="let data">
                                        <button class="link" (click)="fileView(data)">
                                            {{ data.fname }}
                                        </button>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let data" class="no-break">
                                        <button mat-icon-button (keyup.enter)="editDock(data)" *ngIf="!isviewemployee">
                                            <mat-icon (click)="editDock(data)" title="Edit"
                                                *ngIf="data.status == 'Submitted'">edit</mat-icon>
                                        </button>
                                        <button mat-icon-button (keyup.enter)="deleteDock(data)"
                                            *ngIf="!isviewemployee">
                                            <mat-icon (click)="deleteDock(data)" title="Delete"
                                                *ngIf="data.status == 'Submitted'">
                                                delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="documentTableColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: documentTableColumns"></tr>
                            </table>
                        </div>
                    </form>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>