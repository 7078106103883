<div fxLayout="column" class="first-col">
  <mat-card>

    <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title class="title"> Induction Conducted By </mat-card-title>
      <div fxLayoutAlign="end" *ngIf="ishide">
        <button mat-icon-button style="padding-right: 40px;" (click)="Add()" matTooltip="Add New">
          <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <form [formGroup]="inductionForm" autocomplete="off">
      <div *ngIf="!ishide">
        <div class="dev-Pad" fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Program Type</mat-label>
            <mat-select required formControlName="programType" tabindex="1">
              <input matInput [formControl]="searchControl" type="text" placeholder="  Search" class="search-align">
              <ng-container>
                <div *ngFor="let r of filteredprograms">
                  <mat-option [value]="r.id">{{r.description}}</mat-option>
                </div>

              </ng-container>
            </mat-select>
            <mat-error class="reg-error" *ngIf="inductionForm.controls.programType.hasError('required')">
              {{EM2}}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Department</mat-label>
            <mat-select required formControlName="department" tabindex="2">
              <input matInput [formControl]="searchControlforDep" type="text" placeholder="  Search"
                class="search-align">
              <ng-container>
                <div *ngFor="let r of filteredDep">
                  <mat-option [value]="r.id">{{r.deptname}}</mat-option>
                </div>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="inductionForm.controls.department.errors?.required">
              {{EM2}}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex fxFlex.xs="100" fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>Conduct By</mat-label>
            <mat-select (openedChange)="openedSearch($event)" [compareWith]="compareFn" tabindex="3" required
              formControlName="conductBy" multiple [disabled]="!isEnable">

              <mat-select-trigger>
                <span *ngFor="let item of inductionForm.controls.conductBy.value">
                  {{ item.empname ? item.empname:item.conductby }} ,
                </span>
              </mat-select-trigger>
              <div class="select-container" *ngIf="flag">
                <input matInput [formControl]="searchControlforCon" type="text" placeholder="  Search"
                  class="search-align">
                <mat-option (onSelectionChange)="selectedEmployesChange($event)" *ngFor="let emp of filterdCon"
                  [value]="emp">{{emp.employee_code}}__{{emp.empname}}
                </mat-option>
              </div>
              <div class="select-container" *ngIf="!flag">
                <input matInput [formControl]="searchControlforCon" type="text" placeholder="  Search"
                  class="search-align">
                <mat-option (onSelectionChange)="selectedEmployesChange($event)" *ngFor="let emp of filterdCon"
                  [value]="emp">{{emp.employee_code}}__{{emp.empname}}
                </mat-option>
              </div>
            </mat-select>
            <mat-error class="con-error" *ngIf="inductionForm.controls.conductBy.errors?.required">
              {{EM2}}
            </mat-error>
          </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="center">
          <button [disabled]="btnDisable" *ngIf="!isUpdate" class="btn btn-primary mr-2 mb-2" (click)="submit()"
            type="submit" tabindex="4">
            <span>Submit</span>
          </button>
          <button [disabled]="btnDisable" *ngIf="isUpdate" class="btn btn-primary mr-2 mb-2" (click)="submit()"
            type="submit" tabindex="5">
            <span>Update</span>
          </button>
          <button class="btn btn-danger mr-2 mb-2" (click)="close()">
            <span>Cancel</span>
          </button>

          <br />
        </div>
      </div>
    </form>

    <!-- <mat-divider></mat-divider> -->
    <div fxLayout="column" *ngIf="ishide">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" class="dev-Pad"
        style="padding-top: 5px;height: 70px;">
        <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
          <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
          <mat-label>Search </mat-label>
          <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100" />
        </mat-form-field>
      </div>
      <div fxLayout="column" class="table-pad">

        <div class="mat-elevation-z1 example-container">

          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef>S. No.</th>
              <td mat-cell *matCellDef="let element; let i=index;">
                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
            </ng-container>

            <ng-container matColumnDef="programtype">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">
                Program Type </th>
              <td mat-cell *matCellDef="let row">{{row.description}}
              </td>
            </ng-container>
            <ng-container matColumnDef="department">
              <th mat-header-cell *matHeaderCellDef>
                Department </th>
              <td mat-cell *matCellDef="let row">{{row.departme}}
              </td>
            </ng-container>

            <ng-container matColumnDef="employee">
              <th mat-header-cell *matHeaderCellDef>
                Employees </th>
              <td mat-cell *matCellDef="let row">
                <span *ngFor="let v of row.empids">{{v.empname}}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                <mat-radio-group>
                  <mat-radio-button class="mr-2" color="primary" *ngFor="let status of statusList;index as i;"
                    (change)="statusUpdate(status.id,row)" [checked]="status.id == row.status"
                    [value]="status.id">{{status.name}}</mat-radio-button>
                </mat-radio-group>

              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let row">
                <button mat-icon-button (keyup.enter)="edit($event,row)">
                  <mat-icon (click)="edit($event,row)" title="Edit">edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4" style="text-align: center;">No data found</td>
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>
      </div>
    </div>
  </mat-card>
</div>