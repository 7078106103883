<div fxLayout="column" class="first-col">
  <mat-card class="mat-elevation-z0">
    <mat-card-header class="headerbox">
      <mat-card-title class="title"> HR Regularization </mat-card-title>
    </mat-card-header>
    <div fxLayout="column">
      <form [formGroup]="attendanceRequestForm" fxLayout.xs="column" autocomplete="off">

        <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100" fxLayoutAlign="space-between center"
          style="height:70px; padding-top: 3%;">

          <mat-form-field fxFlex="30" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Location</mat-label>
            <mat-select formControlName="location" required tabindex="1">
              <input class="search-align" type="text" placeholder="Search" [formControl]="searchControl" matInput>
              <mat-option *ngFor="let list of filteredLocations"
                [value]="list.id">{{list.cityname}}-{{list.location}}</mat-option>
            </mat-select>
            <mat-error class="con-error" *ngIf="attendanceRequestForm.controls.location.errors?.required">
              Please select an option
          </mat-error>
            <!-- <mat-select formControlName="location" required tabindex="1">
              <ng-container *ngFor="let e of worklocationDetails">
                <mat-option [value]="e.id">{{e.cityname}}-{{e.location}}</mat-option>
              </ng-container>
            </mat-select> -->
          </mat-form-field>

          <mat-form-field fxFlex="30" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Search</mat-label>
            <input formControlName="search" matInput (keyup)="applyFilter($event)" [tabIndex]="1" placeholder="Search"
              #input>
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>

        <div fxLayout="row" *ngIf="isRadio">
          <div style="padding-left: 1%;">
            <br>
            <mat-radio-group (change)="radioChange($event)">
              <mat-radio-button value="1" [checked]="true"><b>Full Day</b></mat-radio-button>&nbsp;
              <mat-radio-button value="2"><b>Half Day</b></mat-radio-button>&nbsp;
              <!-- <mat-radio-button value="3"><b>Pending Approvals</b></mat-radio-button> -->
            </mat-radio-group>
          </div>
        </div>
        <br>

        <!-- fullday table start -->
        <div class="tableP" *ngIf="isFullday">
          <div class="mat-elevation-z1 example-container">

            <table mat-table [dataSource]="dataSource" matSort class="mat-table">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef> S. No.</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="empoyeeid">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Date </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.attendancedate |
                  date:'dd-MM-yyyy'}}</td>
              </ng-container>
              <ng-container matColumnDef="empname">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp. Name </th>
                <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
              </ng-container>
              <ng-container matColumnDef="shift">
                <th mat-header-cell *matHeaderCellDef> Shift </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  {{row.shiftname}}
                </td>
              </ng-container>

              <ng-container matColumnDef="intime">
                <th mat-header-cell *matHeaderCellDef> In Time</th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span *ngIf="enable != row.id">{{row.logintime}} </span>
                  <span *ngIf="enable === row.id">
                    <mat-form-field appearance="outline">
                      <input formControlName="intime" matInput mask="00:00:00" placeholder="{{ row.shift_start_time }}"
                        maxlength="100" />

                    </mat-form-field>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="outtime">
                <th mat-header-cell *matHeaderCellDef> Out Time</th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span *ngIf="enable != row.id">{{row.logouttime}} </span>
                  <span *ngIf="enable === row.id">
                    <mat-form-field appearance="outline">
                      <input formControlName="outtime" matInput mask="00:00:00" placeholder="{{ row.shift_end_time }}"
                        maxlength="100" />
                    </mat-form-field>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">

                  <!-- full day functionality-- *ngIf="row.halfday == null " -->

                  <button mat-icon-button [tabIndex]="2" (keyup.enter)="edit(row)"
                    *ngIf="row.isedit && enable != row.id">
                    <mat-icon class="mr-1" matTooltip="Edit" (click)="edit(row)">edit</mat-icon>
                  </button>

                  <button mat-icon-button [tabIndex]="2" (keyup.enter)="saveRegularization(row)"
                    *ngIf="enable === row.id">
                    <mat-icon class="mr-1" title="Save" (click)="saveRegularization(row)">save</mat-icon>
                  </button>

                  <button mat-icon-button [tabIndex]="2" (keyup.enter)="canceledit()" *ngIf="enable === row.id">
                    <mat-icon class="mr-1" title="Cancel" (click)="canceledit()">cancel</mat-icon>
                  </button>


                  <span *ngIf="!btnHide">
                    <a style="padding-right: 10px;" title="Leave request" (click)="setLeaveRegularization(row)">
                      <img src="assets\img\leave-request-black.png" style="height: 35px" />
                    </a>
                  </span>
                </td>
              </ng-container>

              <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumnsForApprovals; sticky: true">
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsForApprovals;"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
              </tr>
            </table>
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
          </div>
          <br>
          <span *ngIf="isFullday && fullDayList.length > 0" style="color:red">* Use multi selection for Attendance/Week
            off </span>

          <div fxLayoutAlign="center">
            <!-- is full day submit button -->

            <div *ngIf="isFullday && fullDayList.length > 0">
              <button class="btn btn-primary custom mr-2 mb-2" [ngClass]="{'disable':btnDisable}"
                [disabled]="btnDisable" (click)="submitFulldayRegularization()" type="submit">
                <span>Attendance</span>
              </button>

              <button class="btn btn-primary custom mr-2 mb-2" [ngClass]="{'disable':btnDisable}"
                [disabled]="btnDisable" (click)="setWeekOffRegularization()" type="submit">
                <span>Week off</span>
              </button>
              <button class="btn btn-danger mr-2 mb-2" (click)="clear()">
                <span>Clear</span>
              </button>
            </div>

            <!-- is pending approvals submit button -->
            <div *ngIf="isPendingApprovalsView">
              <button class="btn btn-primary custom mr-2 mb-2" (click)="approveRegularization()" type="submit">
                <span>Approve</span>
              </button>
              <button class="btn btn-danger custom mr-2 mb-2" (click)="rejectRegularization()">
                <span>Reject</span>
              </button>
            </div>

            <br />
          </div>

        </div>
        <!-- fullday table end -->


        <!-- halfday table start -->
        <div class="tableP" *ngIf="isHalfdayView">
          <div class="mat-elevation-z1 example-container">

            <table class="mat-table" mat-table [dataSource]="dataSource2" matSort>

              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef> S.No</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="empoyeeid">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Date </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.attendancedate |
                  date:'dd-MM-yyyy'}}</td>
              </ng-container>

              <ng-container matColumnDef="empname">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Employee Name </th>
                <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
              </ng-container>

              <ng-container matColumnDef="shift">
                <th mat-header-cell *matHeaderCellDef> Shift </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  {{row.shiftname}}
                </td>
              </ng-container>

              <ng-container matColumnDef="intime">
                <th mat-header-cell *matHeaderCellDef> In Time</th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span *ngIf="enable != row.id">{{row.logintime}} </span>
                  <span *ngIf="enable === row.id">
                    <mat-form-field appearance="outline">
                      <input formControlName="intime" matInput mask="00:00:00" placeholder="{{ row.shift_start_time }}"
                        maxlength="100" />
                    </mat-form-field>
                  </span>
                </td>
              </ng-container>


              <ng-container matColumnDef="outtime">
                <th mat-header-cell *matHeaderCellDef> Out Time</th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span *ngIf="enable != row.id">{{row.logouttime}} </span>
                  <span *ngIf="enable === row.id">
                    <mat-form-field appearance="outline">
                      <input formControlName="outtime" matInput mask="00:00:00" placeholder="{{ row.shift_end_time }}"
                        maxlength="100" />
                    </mat-form-field>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">

                  <!-- half day functionality -->
                  <span style="padding-left: 5px;">
                    <button mat-icon-button>
                      <a style="padding-right: 10px;" title="Half Day Regularization"
                        (click)="halfDayRegularization(row)">
                        <img src="assets\img\halfday.png" style="height: 28px" />
                      </a>
                    </button>
                  </span>
                </td>
              </ng-container>

              <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
              </tr>
            </table>

            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes2() : [5, 10, 20]"></mat-paginator>
          </div>
        </div>
        <!-- halfday table end -->

      </form>
    </div>
  </mat-card>
</div>