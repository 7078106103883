<div fxLayout="column" class="first-col">
    <mat-card>

        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Department </mat-card-title>
            <div style="margin-right: 2%;" *ngIf="isdata">
                <button mat-icon-button style="padding-right: 40px;" (click)="Add()" matTooltip="Add New">
                    <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>
        <form [formGroup]="departmentForm" autocomplete="off">
            <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
                <div fxLayout="row" fxLayout.xs="row" style="padding-top: 1%;padding-left: 1%;">
                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                        <mat-label>Department</mat-label>
                        <input formControlName="department" matInput placeholder="" noLeadingWhitespace required
                            maxlength="64">
                        <!-- <mat-error class="con-error" *ngIf="departmentForm.controls.department.errors?.cannotContainSpace">
                        Please enter valid data
                    </mat-error> -->
                        <mat-error class="con-error" *ngIf="departmentForm.controls.department.errors?.required">
                            {{msgEM1}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayoutAlign="center">
                    <button class="btn btn-primary mr-2 mb-2" (click)="setdepartment()" [disabled]="btnDisable"
                        [ngClass]="{'disable':btnDisable}" type="submit">
                        <span>Submit</span>
                    </button>

                    <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>


            </div>
        </form>
        <!-- <mat-divider></mat-divider> -->
        <div fxLayout="column" *ngIf="isdata">
            <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
                <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
                    <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2"
                        appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                        <mat-label>Search</mat-label>
                        <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
                    </mat-form-field>
                </span>
            </div>
            <form [formGroup]="departmentForm" autocomplete="off">
                <div fxLayout="column" class="table-pad">
                    <div class="mat-elevation-z1  example-container">
                        <table mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef>S. No.</th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="deptname">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                                <td mat-cell *matCellDef="let row">
                                    <span *ngIf="enable != row.id">{{row.deptname}}&nbsp;</span>
                                    <mat-form-field appearance="outline" *ngIf="enable === row.id">
                                        <input formControlName="department" value="row.deptname" matInput
                                            placeholder="">
                                        <mat-error class="con-error"
                                            *ngIf="departmentForm.controls.department.errors?.required ||departmentForm.controls.department.errors?.['whitespace']">
                                            {{msgEM1}}
                                        </mat-error>
                                    </mat-form-field>

                                </td>
                            </ng-container>


                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef style="padding-left: 60px"> Status </th>
                                <!-- <td mat-cell *matCellDef="let row"> {{row.status}}</td> -->
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                    <!-- <mat-radio-group >
                                    <mat-radio-button value="{{row.status}}">Active</mat-radio-button> &nbsp;
                                    <mat-radio-button value="{{row.status}}">Inactive</mat-radio-button>
                                  </mat-radio-group> -->
                                    <mat-radio-group>
                                        <mat-radio-button class="mr-2" color="primary"
                                            *ngFor="let halfs of arrayValue;index as i;"
                                            (change)="status(halfs.id,row.id,row.deptname)"
                                            [checked]="halfs.id == row.status"
                                            [value]="halfs.id">{{halfs.name}}</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="Action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <!-- <td mat-cell *matCellDef="let row"> </td> -->
                                <td mat-cell *matCellDef="let row">
                                    <button mat-icon-button *ngIf="enable != row.id" (keyup.enter)="edit($event,row)">
                                        <mat-icon (click)="edit($event,row)" title="Edit">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button *ngIf="enable == row.id"
                                        (keyup.enter)="save($event,row.id,departmentForm.controls.department.value,row)">
                                        <mat-icon
                                            (click)="save($event,row.id,departmentForm.controls.department.value,row)"
                                            title="Save">save</mat-icon>
                                    </button>
                                    <button mat-icon-button *ngIf="enable == row.id"
                                        (keyup.enter)="canceledit($event,row.id)">
                                        <mat-icon (click)="canceledit($event,row.id)" title="Cancel">cancel</mat-icon>
                                    </button>
                                </td>
                            </ng-container>


                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="3" style="text-align: center;">No data found</td>
                            </tr>
                        </table>

                        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
                        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                    </div>
                </div>
            </form>
        </div>


    </mat-card>
</div>