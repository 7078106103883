<div fxLayout="column" class="first-col ">

    <mat-card>

        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="start">
            <mat-card-title class="title"> HR Policies </mat-card-title>

        </mat-card-header>
        <div>

            <div fxLayout="column">
                <form [formGroup]="emsPoliciesForm" autocomplete="off">
                    <h2 style="padding-top: 5px;padding-left: 10px;">Common rules for all EMS types</h2>
                    <div class="mat-elevation-z1 example-container">
                        <table mat-table [dataSource]="dataSource" matSort>

                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef> S. No. </th>
                                <td mat-cell *matCellDef="let data; let i = index;"> {{i+1}} </td>

                            </ng-container>


                            <ng-container matColumnDef="configurationrules">
                                <th mat-header-cell *matHeaderCellDef> Configuration Rules </th>
                                <td mat-cell *matCellDef="let data" style="width: 70%;">{{data.description}}</td>
                            </ng-container>

                            <ng-container matColumnDef="data">
                                <th mat-header-cell *matHeaderCellDef>Value</th>
                                <td mat-cell *matCellDef="let data ;let i = index;" style="padding-top: 1%;">

                                    <div *ngIf="i==0">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="pwdMinLength" readonly onlyNumber matInput required>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==1">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="pwdMaxLength" onlyNumber matInput required>
                                        </mat-form-field>
                                    </div>



                                    <div *ngIf="i==2">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="pwdLastMatchCount" onlyNumber matInput required>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==3">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="pwdStartCapital" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==4">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="pwdMinCapitalCount" onlyNumber matInput required>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==5">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="pwdIsAllowSpecialChar" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==6">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="pwdMinCountSpecialChar" onlyNumber matInput
                                                required>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==7">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="pwdMinSmallLeterCount" onlyNumber matInput required>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==8">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="pwdMinNumberCount" onlyNumber matInput required>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==9">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="sendAutoRemindOnboard" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==10">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="probationPeriod" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==11">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="probationPeriodDays" onlyNumber matInput required>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==12">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="sendAutoRemindOffboard" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==13">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="noticePeriod" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==14">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="noticePeriodDays" onlyNumber matInput required>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==15">
                                        <!-- <mat-form-field appearance="outline" style="width:120px">
                                                    <input formControlName="defaultWeekoff1" onlyNumber matInput required>
                                                </mat-form-field> -->
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="defaultWeekoff1"
                                                [disabled]="!isEditDefaultRules" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of weekList">
                                                        <mat-option value={{b.value}}>{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>

                                    </div>

                                    <div *ngIf="i==16">
                                        <!-- <mat-form-field appearance="outline" style="width:120px">
                                        <input formControlName="defaultWeekoff2" onlyNumber matInput required>
                                    </mat-form-field> -->
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="defaultWeekoff2"
                                                [disabled]="!isEditDefaultRules" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of weekList">
                                                        <mat-option value={{b.value}}>{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==17">
                                        <!-- <mat-form-field appearance="outline" style="width:120px">
                                        <input formControlName="defaultWeekoff3" onlyNumber matInput required>
                                    </mat-form-field> -->
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="defaultWeekoff3"
                                                [disabled]="!isEditDefaultRules" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of weekList">
                                                        <mat-option value={{b.value}}>{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==18">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="autoShiftAssign"
                                                [disabled]="!isEditDefaultRules" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==19">
                                        <mat-form-field *ngIf="!autoDisable" appearance="outline" style="width:120px">
                                            <mat-select formControlName="automaticShift" style="width:50%"
                                                [disabled]="autoShiftDisable">
                                                <ng-container>
                                                    <div *ngFor="let b of rotationShiftChecklist">
                                                        <mat-option [value]="b.value">{{ b.name }}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field *ngIf="autoDisable" appearance="outline" style="width:120px">
                                            <mat-select formControlName="automaticShift" style="width:50%"
                                                [disabled]="autoDisable">
                                                <ng-container>
                                                    <div *ngFor="let b of rotationShiftChecklist">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==20">

                                        <mat-form-field appearance="outline" *ngIf="!autoDisable" style="width:120px">
                                            <mat-select formControlName="automaticShiftDuration" style="width:50%"
                                                [disabled]="autoShiftDisable">
                                                <ng-container>
                                                    <div *ngFor="let b of shiftDurationDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field *ngIf="autoDisable" appearance="outline" style="width:120px">
                                            <mat-select formControlName="automaticShiftDuration" style="width:50%"
                                                [disabled]="autoDisable">
                                                <ng-container>
                                                    <div *ngFor="let b of shiftDurationDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==21">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="howManyMaxLateAttendance" onlyNumber maxlength="2"
                                                matInput>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==22">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="howManyLeavesDeduction" min="0.5" maxlength="3"
                                                (change)="onChange()" matInput>
                                            <mat-error class="con-error"
                                                *ngIf="emsPoliciesForm.controls.howManyLeavesDeduction.errors?.pattern">
                                                Please enter valid data
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==23">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="leaveTypeDeduction"
                                                [disabled]="!isEditDefaultRules" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of leaveTypeList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==24">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="defaultShift" [disabled]="!isEditDefaultRules"
                                                style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of shiftDataList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==25">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="defaultShiftDuration"
                                                [disabled]="!isEditDefaultRules" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of shiftDurationDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==26">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="minWorkHoursForHalfday" onlyNumber maxlength="1"
                                                matInput>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==27">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="minWorkHoursForFullday" onlyNumber maxlength="2"
                                                matInput>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==28">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="permissionPaidOrNot"
                                                [disabled]="!isEditDefaultRules" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==29">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="flexibleShiftEnabled"
                                                [disabled]="!isEditDefaultRules" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==30">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="flexibleShiftDuration" onlyNumber maxlength="2"
                                                matInput>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==31">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="weekoff_option"
                                                [disabled]="!isEditDefaultRules" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="i==32">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <input formControlName="regularzation_Duration" onlyNumber maxlength="2"
                                                matInput>
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="i==33">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="edit_profile" [disabled]="!isEditDefaultRules"
                                                style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="i==34">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="punchout_time_enable"
                                                [disabled]="!isEditDefaultRules" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="i==35">
                                        <mat-form-field appearance="outline" style="width:120px">
                                            <mat-select formControlName="mapping_enable"
                                                [disabled]="!isEditDefaultRules" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of defaultDropdownList">
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>


                            <!-- <ng-container matColumnDef="addditionalinformation">
                                    <th mat-header-cell *matHeaderCellDef > Additional Information </th>
                                    <td mat-cell *matCellDef="let data" style="padding-left: 1%;">{{data.data}}</td>
    
                                </ng-container> -->


                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>

                        </table>

                    </div><br>
                    <div fxLayoutAlign="center">
                        <button *ngIf="isEditDefaultRules" class=" btn btn-primary mr-2"
                            (click)="saveDefaultrules()">Update
                        </button>

                        <button *ngIf="isEditDefaultRules" class="btn btn-danger mr-2"
                            (click)="cancelDefaultRules()">Cancel
                        </button>
                        <button *ngIf="!isEditDefaultRules" style="float: right" class=" btn btn-primary"
                            (click)="editDefaultRules()">Edit
                        </button>
                    </div>

                </form>

            </div>

        </div>
    </mat-card>

</div>