<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox">
            <mat-card-title class="title"> Attendance Request </mat-card-title>
        </mat-card-header>
        <div fxLayout="column">
            <form [formGroup]="requestform" autocomplete="off" style="padding-left: 1%;padding-top: 1%;">
                <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center">

                    <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Work Type </mat-label>
                        <mat-select formControlName="workType" required>
                            <input class="search-align" type="text" placeholder="Search" [formControl]="searchControl"
                                matInput>
                            <mat-option *ngFor="let list of filteredWorkTypes" [value]="list.id">{{ list.type
                                }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="requestform.controls['workType'].invalid">
                            {{requiredOption}}
                        </mat-error>
                    </mat-form-field>


                    <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline" style="height: 60px;">
                        <mat-label>From Date </mat-label>
                        <input matInput readonly required [min]="minFromDate" [max]="maxFromDate"
                            [matDatepicker]="fromDate" [matDatepickerFilter]="myDateFilter"
                            (dateInput)="fromDateChange('input', $event)" placeholder="DD-MM-YYYY"
                            (click)="fromDate.open()" formControlName="fromDate" [tabIndex]="3">
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                        <mat-datepicker #fromDate></mat-datepicker>
                        <mat-error class="con-error" *ngIf="requestform.controls.fromDate.errors?.required">
                            {{requiredOption}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>To Date </mat-label>
                        <input matInput readonly required [min]="minToDate" [max]="maxToDate" [matDatepicker]="toDate"
                            [matDatepickerFilter]="myDateFilter" placeholder="DD-MM-YYYY"
                            (dateInput)="toDateChange('input', $event)" formControlName="toDate" (click)="toDate.open()"
                            [tabIndex]="4">
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
                        <mat-datepicker #toDate></mat-datepicker>
                        <mat-error class="con-error" *ngIf="requestform.controls.toDate.errors?.required">
                            {{requiredOption}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div *ngIf="!isRequestView" fxLayout="row" fxLayoutAlign.xs="center">
                    <mat-form-field fxFlex="32" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Reason</mat-label>
                        <textarea formControlName="reason" onlyAlphabets required maxlength="250" matInput
                            placeholder="Reason" noLeadingWhitespace [tabIndex]="6" rows="1">
                </textarea>
                        <mat-error class="con-error" *ngIf="requestform.controls.reason.errors?.required">
                            {{requiredField}}
                        </mat-error>
                        <mat-error class="con-error" *ngIf="requestform.controls.reason.errors?.maxlength">
                            Reason should be below 250 charactors.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div *ngIf="isRequestView" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                    <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Applied Date</mat-label>
                        <input formControlName="appliedDate" matInput placeholder="DD-MM-YYYY" readonly>
                    </mat-form-field>

                    <mat-form-field *ngIf="isRequestView" fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Shift</mat-label>
                        <input formControlName="shift" readonly required matInput>
                        <mat-error *ngIf="requestform.controls['shift'].invalid"> {{requiredOption}} </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="isRequestView" fxFlex fxFlex.lt-lg="47" fxFlex.xs="100" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Reason</mat-label>
                        <textarea formControlName="reason" required minlength="3" maxlength="250" matInput
                            placeholder="Reason" [tabIndex]="6" rows="1" noLeadingWhitespace onlyAlphabets>
                        </textarea>
                        <mat-error class="con-error"
                            *ngIf="requestform.controls.reason.errors?.invalid ||requestform.controls.reason.errors?.['whitespace']">
                            {{requiredField}}
                        </mat-error>
                        <mat-error class="con-error" *ngIf="requestform.controls.reason.errors?.maxlength">
                            Reason should be below 250 charactors.
                        </mat-error>
                        <mat-error class="con-error" *ngIf="requestform.controls.reason.errors?.minlength">
                            Reason should be above 3 characters charactors.
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- half day attendance -->

                <div fxLayout="column">
                    <mat-checkbox style="margin-left: 10px;" formControlName="firstHalf"
                        (change)="firstSelected($event)" [checked]="isFirstHalf" [disabled]="fhalfDisable">First Half
                    </mat-checkbox>

                    <mat-checkbox style="margin-left: 10px;" formControlName="secondHalf"
                        (change)="secondSelected($event)" [checked]="isSecondHalf" [disabled]="shalfDisable">Second Half
                    </mat-checkbox>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center">

                    <mat-form-field *ngIf="isCommentView" fxFlex="65.5" fxFlex.lt-lg="47" fxFlex.xs="100"
                        class="mx-2 my-2" appearance="outline">
                        <mat-label *ngIf="isStatus">Approved Reason</mat-label>
                        <mat-label *ngIf="!isStatus">Rejected Reason</mat-label>
                        <textarea formControlName="comment" readonly rows="1" matInput placeholder="Reason"></textarea>
                    </mat-form-field>

                    <div fxLayout="row" style="align-items: center;">
                        <span *ngIf="pdfName" class="no-break" style="margin-left: 7px;">Upload File:</span>&nbsp;
                        <button class="toolbar-button" ngClass.lt-md="mob_file" mat-button (click)="fileView();"
                            class="link" style="font-size: 16px;text-align: start;" *ngIf="pdfName">
                            {{ pdfName }}
                        </button>
                        &nbsp;
                        <input *ngIf="!pdfName" style="margin-left: 0px" type="file" formControlName="document"
                            (change)="onSelectFile($event)" single />
                        &nbsp;
                        <mat-icon class="toolbar-button" mat-button title="Edit" *ngIf="pdfName && !isRequestView"
                            (click)="editdoc()">edit</mat-icon>
                        &nbsp;
                        <span>
                            <mat-icon title="Delete" *ngIf="requestform.controls.document.value && iseditDoc"
                                (click)="deleteIcon()">delete</mat-icon>
                        </span>
                        &nbsp;
                    </div>
                </div>



                <div fxLayoutAlign="center">
                    <button [disabled]="btnDisable" [tabIndex]="7" *ngIf="!isRequestView && !isEditView"
                        class="btn btn-primary mr-2 mb-2" [ngClass]="{'disable':btnDisable}" (click)="saveRequest()"
                        type="submit">
                        <span>Submit</span>
                    </button>
                    <button [disabled]="btnDisable" [tabIndex]="7" *ngIf="isEditView" [ngClass]="{'disable':btnDisable}"
                        class="btn btn-primary mr-2 mb-2" (click)="updateRequest()" type="submit">
                        <span>Update</span>
                    </button>
                    <button [tabIndex]="8" class="btn btn-danger mr-2 mb-2" (click)="resetform()" type="submit">
                        <span>Cancel</span>
                    </button>
                </div>

            </form>
        </div>
        <div>

            <mat-divider></mat-divider>


            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="end"
                style="height: 70px;padding-top: 1%;">
                <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline"
                    style="padding-right: 16px;">
                    <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                    <mat-label>Search</mat-label>
                    <input (keyup)="applyFilter($event)" matInput [tabIndex]="9" maxlength="100">
                </mat-form-field>
            </div>

            <div fxLayout="column" class="tableP">
                <div class="example-container mat-elevation-z1">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> S. No.</th>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                        </ng-container>


                        <ng-container matColumnDef="worktype">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-break"> Work Type </th>
                            <td mat-cell *matCellDef="let row"> {{row.worktype}}</td>
                        </ng-container>


                        <ng-container matColumnDef="fromdate">
                            <th mat-header-cell *matHeaderCellDef class="no-break"> From Date</th>
                            <td mat-cell *matCellDef="let row" class="no-break"> {{row.fromdate | date:'dd-MM-yyyy'}}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="todate">
                            <th mat-header-cell *matHeaderCellDef class="no-break"> To Date</th>
                            <td mat-cell *matCellDef="let row" class="no-break"> {{row.todate | date:'dd-MM-yyyy'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="reason">
                            <th mat-header-cell *matHeaderCellDef> Reason </th>
                            <td mat-cell *matCellDef="let row"
                                style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;">
                                {{row.reason}} </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                            <td mat-cell *matCellDef="let row" [style.color]="row.status"> {{row.status}} </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row" class="no-break">
                                <button *ngIf="row.status=='Submitted'" mat-icon-button [tabIndex]="10"
                                    (keyup.enter)="editRequest(row)">
                                    <mat-icon class="mr-2" title="Edit" (click)="editRequest(row)">
                                        edit</mat-icon>
                                </button>
                                <button *ngIf="row.status=='Submitted'" mat-icon-button [tabIndex]="11"
                                    (keyup.enter)="DeleteRequestPopup(row)">
                                    <mat-icon class="mr-2" title="Delete"
                                        (click)="DeleteRequestPopup(row)">delete</mat-icon>
                                </button>
                                <button *ngIf="row.status!='Submitted'" mat-icon-button [tabIndex]="12"
                                    (keyup.enter)="requestView(row)">
                                    <mat-icon class="mr-2" title="View" (click)="requestView(row)">
                                        visibility</mat-icon>
                                </button>
                            </td>

                        </ng-container>
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="7" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
            </div>
        </div>
    </mat-card>
</div>