import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { ReportsService } from '../../reports.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { MatDialog } from '@angular/material/dialog';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-employee-missed-punch-out-report',
  templateUrl: './employee-missed-punch-out-report.component.html',
  styleUrls: ['./employee-missed-punch-out-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EmployeeMissedPunchOutReportComponent implements OnInit {
  @ViewChild('table') table!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  searchForm: any = UntypedFormGroup;
  employeeList: any = [];
  arrayList: any = [];
  minFromDate: Date | undefined;
  maxFromDate: Date | undefined;
  minToDate: Date | null;
  maxToDate: Date | undefined;
  currentDate: Date = new Date();
  List: any[] = [];
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns: string[] = ['sno', 'empid', 'empname','attendancedate','intime', 'location'];
  pageLoading = true;
  searchControl = new FormControl('');
  filteredEmployees: any = [];
  userSession: any;
  isLoading = false;
  locationList: any = [];
  filteredLocations: any = [];
  companyDBName: any;
  date: any;
  minDate: any = new Date('2000-01-01');
  max = this.getEndOfMonth(new Date());
  monthfirstDay: any;
  monthLastDay: any;
  /**Here select Month and Date from month year picker */
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }
  months = [{ id: 0, month: 'Jan' }, { id: 1, month: 'Feb' }, { id: 2, month: 'Mar' }, { id: 3, month: 'Apr' }, { id: 4, month: 'May' }, { id: 5, month: 'Jun' }, { id: 6, month: 'Jul' }, { id: 7, month: 'Aug' }, { id: 8, month: 'Sep' }, { id: 9, month: 'Oct' }, { id: 10, month: 'Nov' }, { id: 11, month: 'Dec' }]


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public datePipe: DatePipe,
    private reportsService: ReportsService,
    private companyService: CompanySettingService,
    public dialog: MatDialog
  ) {

    this.minFromDate = new Date();
    this.minFromDate.setDate(this.currentDate.getDate() - 90);
    this.maxFromDate = new Date();
    this.minToDate = new Date();
    this.minToDate.setDate(this.currentDate.getDate() - 90);
    this.maxToDate = new Date();

  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      empname: ['', Validators.required],
      fromDate: [new Date(), Validators.required],
      toDate: [this.max, Validators.required],
    });
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.companyDBName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    this.date = new UntypedFormControl(moment())
    let prevDate = new Date();
    prevDate.setDate(0);
    this.Searchform();
  }

  /** Function to get the end of the current month*/
  getEndOfMonth(date: Date): Date {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0); // Last day of the current month
  }

  fromDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.minToDate = event.value;
    if (event.value !== null) {
      this.maxToDate = new Date(
      );
    }
  }
  /**missedPunchOutReport Search Functionality */
  Searchform() {
    var firstDay = new Date((this.date.value._d).getFullYear(), (this.date.value._d).getMonth(), 1);
    var lastDay = new Date((this.date.value._d).getFullYear(), (this.date.value._d).getMonth() + 1, 0);
    this.monthfirstDay = this.datePipe.transform(firstDay, "yyyy-MM-dd")
    this.monthLastDay = this.datePipe.transform(lastDay, "yyyy-MM-dd")
    let data = {
      "remployee_id":null,
      "employee_id":this.userSession.id,
      "calendar_date": this.monthfirstDay
    }
    this.arrayList = []
    this.dataSource = new MatTableDataSource()
    this.reportsService.getMissedOutPunchesReport(data).subscribe((res: any) => {
      if (res.status) {
        this.arrayList = res.data
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
    })

  }

  /**Clear Functionality */
  resetform() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Attendance/EmployeeMissedPunchOutReport"]));
  }

  exportAsXLSX() {
    /** */
    let toDateData = new Date().getMonth() != new Date(this.monthLastDay).getMonth() ? this.datePipe.transform(this.monthLastDay, "dd-MM-yyyy") : this.datePipe.transform(new Date(), "dd-MM-YYYY")

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Employees_miised_punch_Report');

    /* save to file */
    XLSX.writeFile(wb, this.datePipe.transform(this.monthfirstDay, "dd-MM-yyyy") + '-' + toDateData + '-' + 'Over_Time_Report.xlsx');

  }

  getPageSizes(): number[] {

    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  /**Search Functioanlity */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /**Over Time Report PDF Functionality */
  downloadPDF() {
    let toDateData = new Date().getMonth() != new Date(this.monthLastDay).getMonth() ? this.datePipe.transform(this.monthLastDay, "dd-MM-yyyy") : this.datePipe.transform(new Date(), "dd-MM-YYYY")
    let data = {
      'list': this.arrayList,
      'report_date': this.datePipe.transform(this.monthfirstDay, "dd-MM-yyyy") + ' ' + '_' + ' ' + toDateData
    }
    this.reportsService.getOverTimeReportPdf(data).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'employee_over_time_report_from ' + this.datePipe.transform(this.monthfirstDay, "dd-MM-yyyy") + ' ' + ' to ' + ' ' + toDateData + '.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }
}
